import React, { useState, useEffect } from 'react'
import { Card, Col, Image, Row, ProgressBar } from 'react-bootstrap'
import RightArrow from '../../../../assets/images/recruiter_page_assets/arrow-right-long.svg'
import './styles/Subscription.scss'
import moment from 'moment'
import {
  Wrapper
} from './styles/Subscription.styled'
import {
  H1,
  P,
  Button,
  CONTAINER,
  GRID,
  COL,
  A,
} from './styles/Subscription.styled'
import { capitalize } from '../../../utils'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'

const IndexPage = (props) => {
  const {transactionLog_detail } = props
  const [cancelPlan,setCancelPlan] = useState(false)
  const [cancelPlanId,setCancelPlanId] = useState('')
  console.log(props)
  
  const calculateDueDate = (endDate) => {
    var given = moment(endDate, "YYYY-MM-DD");
    var current = moment().startOf('day');
    //Difference in number of days
    return moment.duration(given.diff(current)).asDays();
  }

  const cancleSubscription = () =>{
    let URL = `/subscription/cancel_subscription`
    const payload = new FormData()
    payload.append('subscription[subscription_id]', cancelPlanId)
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (responce) => {
        setCancelPlan(false)
        if (responce.success) {
          window.location.reload();
          return {
            message: responce.message,
            messageType: 'success',
            loading: false,
            autoClose: true,
          }
        } else {
          return {
            message: responce.message,
            messageType: 'failure',
            loading: false,
            autoClose: true,
          }
        }
      },
      createErrorMessage: (e) => {
        return e.message
      },
    })
  }
  return (
    <>
      <div className='subscriptionPage'>
        <Wrapper>
          <div className='sidebar'>
            <div className='sidebar_header'>
              <p>Subscriptions & Billing</p>
            </div>
            <a className='active' href="/subscription">
              Subscriptions
            </a>
            <a href="/subscription/invoice">Invoices</a>
            <a href="/subscription/billing">Billing Information</a>
          </div>
          <div className='containt'>
            <div>
              <Row>
                <Col><h3>Subscriptions</h3></Col>
                <Col className='text-right'>
                  <A href="/subscription/order" onClick={''}>
                    Buy New Plan
                    <Image
                      src={RightArrow}
                      style={{ marginLeft: '15px' }}
                    />
                  </A>
                </Col>
              </Row>
            </div>
            {transactionLog_detail && transactionLog_detail?.length ? (
              transactionLog_detail?.map((transactionLog,key) => {
                if (transactionLog)
                  return (
                    <Card.Body style={{ border: transactionLog?.status == 'active' ? '1px solid #05a452' : '1px solid #dc3545'}}>
                      <Row className="align-items-center">
                        <Col lg={12} md={12} sm={12} xs={12} className="px-2">
                          <Row>
                            <Col className='jobCardColjobCardCol'>
                            <p className='jobCardText'>
                                { (transactionLog?.status == 'active' && transactionLog?.amount > 0) &&
                                  <Button className='cancelbuttom' onClick={()=>{
                                    setCancelPlan(true)
                                    setCancelPlanId(transactionLog?.id)
                                  }}>
                                    Cancel
                                  </Button>
                                }
                                { (transactionLog?.status != 'active') &&
                                  <Button className='cancelbuttom' onClick={()=>{}}>
                                    { (transactionLog?.status != 'in_active') ? capitalize(transactionLog?.status) : 'Inactive'}
                                  </Button>
                                }
                              </p>
                              <span className='jobCardTitle'>
                                {transactionLog?.subscription_plan?.name || 'Free Plan'}
                                {/* {transactionLog.user_subscription.} */}
                              </span>
                            </Col>
                          </Row>
                          
                          <Row>
                            <Col xs="auto" className='mt-2'>
                              <p className='jobCardText'>
                                Date : {moment(transactionLog?.start_date).format('LL')} <b>to</b> {moment(transactionLog?.end_date).format('LL')}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  )
              })
            ) : (
              <div style={{ textAlign: 'center' }}>
                No Active Plan Available
              </div>
            )}

          </div>
        </Wrapper>
      </div>
      {cancelPlan &&
          <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes"
              cancelBtnText="No"
              confirmBtnBsStyle="danger"
              title="Are you sure you want to cancel the subscription?"
              onConfirm={()=>cancleSubscription()}
              onCancel={()=>setCancelPlan(false)}
              focusCancelBtn
              >
          </SweetAlert>
      }
    </>
  )
}
export default IndexPage
