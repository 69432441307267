import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
import ProfileAvatar from '../../common/ProfileAvatar/ProfileAvatar'
import RecruiterOrganization from '../../pages/RecruiterOrganization/RecruiterOrganization'
import styles from './styles/Navbar.module.scss'
import LogoWithText from '../../../../assets/images/logos/navbar-logo.svg'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Signup from './Signup/Signup'
import { getOrganizations } from '../../api/recruiter.api'
import feather from 'feather-icons'

const NavBar = ({
    isAdmin,
    userId,
    avatar_url,
    isEmployer,
    currentUser,
}) => {
    const currentPathname = window.location.pathname

    useEffect(() => {
    
    }, []);

    const guestLinks = (
        <>
            <Nav.Link
                href="/"
                className={`${styles.navbarLink} ${
                    currentPathname === '/'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Home
            </Nav.Link>
            {/* <Nav.Link
                href="/welcome/top_recruiter"
                className={`${styles.navbarLink} ${
                    currentPathname === '/welcome/top_recruiter'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Top Recruiter
            </Nav.Link> */}
            <Nav.Link
                href="/welcome/become_recruiter"
                className={`${styles.navbarLink} ${
                    currentPathname === '/welcome/become_recruiter'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Become A Recruiter
            </Nav.Link>
            <Nav.Link
                href="/welcome/get_marketed"
                className={`${styles.navbarLink} ${
                    currentPathname === '/welcome/get_marketed'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Scale To Millions
            </Nav.Link>
        </>
    )
    const authLinks = (
        <>
            <Nav.Link
                href="/"
                className={`${styles.navbarLink} ${
                    currentPathname === '/'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Home
            </Nav.Link>
            {/* <Nav.Link
                href="/welcome/top_recruiter"
                className={`${styles.navbarLink} ${
                    currentPathname === '/welcome/top_recruiter'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Top Recruiter
            </Nav.Link> */}
            <Nav.Link
                href="/welcome/become_recruiter"
                className={`${styles.navbarLink} ${
                    currentPathname === '/welcome/become_recruiter'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Become A Recruiter
            </Nav.Link>
            <Nav.Link
                href="/welcome/get_marketed"
                className={`${styles.navbarLink} ${
                    currentPathname === '/welcome/become_recruiter'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Scale To Millions
            </Nav.Link>
        </>
    )

    const adminLinks = (
        <>
            <Nav.Link
                href="/admin/dashboard"
                className={`${styles.navbarLink} ${
                    currentPathname === '/admin/dashboard'
                        ? styles.activeLinkColor
                        : ''
                }`}
            >
                Home
            </Nav.Link>
            
        </>
    )

    return (
        <Navbar
            expand="xl"
            className={'align-items-center py-0 ' + styles.navbarContainer}
        >
            <Navbar.Brand href="/" className="d-none d-xl-block">
                <Image src={LogoWithText} rounded className={styles.logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="align-items-center flex-wrap">
                    <ul className={styles.navbarList}>
                        {userId && !isAdmin && authLinks}
                        {userId && isAdmin && !url && adminLinks}
                        {!userId && guestLinks}
                    </ul>
                </Nav>
            </Navbar.Collapse>
            {userId ? (
                <>
                    <ProfileAvatar
                        profileAvatar={avatar_url}
                        userId={userId}
                        organization_id={''}
                        isEmployer={isEmployer}
                        currentUser={currentUser}
                    />
                </>
            ) : (
                <>
                    <a
                        href={`/users/sign_in?page=${
                            currentPathname == '/welcome/employer'
                                ? currentPathname
                                : currentPathname == '/welcome/recruiter'
                                ? currentPathname
                                : '/'
                        }`}
                        className={styles.navbarSignInButton}
                    >
                        Sign In
                    </a>
                    <a
                        href={`/signup/recruiter`}
                        className={styles.signUpDropdownButton}
                    >
                        Sign Up
                    </a>
                    {/* <Signup /> */}
                </>
            )}
        </Navbar>
    )
}

export default NavBar
