import React, { useState } from 'react'
import ReCaptchaV2 from 'react-google-recaptcha'
import { useField , Formik, Field ,Form as FormSubmit } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import {Button } from '../Admin/styles/UserManagementEditPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col, Form} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import ImageOne from '../../../../assets/images/about_us_page_assets/about-us.png'
import './styles/BecomeRecuirter.scss'
import FileButton from '../../common/FileButton/FileButton'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
    Wrapper,
    AboutUsSection,
    TeamSection,
    P
} from './styles/BecomeRecuirterPage.styled'

let finalLinkedInURL = ''

const becomeRecruiterValidation = {
    first_name: Yup.string()
      .required('First name is required')
      .test(
        'first name alphabets only',
        'First Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    last_name: Yup.string()
      .required('Last Name is required')
      .test(
        'last name alphabets only',
        'Last Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    email: Yup.string().email('Must be a valid email').required('Email is required')
        .test('email-unique','This email is already in use',
        async function (value) {
          const res = await axios.get(
            `/cr_users/exists?email=${encodeURIComponent(value)}`
          )
          return !res.data.user_exists
        }
    ),
    phone_number: Yup.string().required('Phone number is required').test(
        'Invalid Number',
        "Please enter valid phone number[ Either 10 digit number or '+' and up to 13 digit numbers ].",
        function (value) {
            if (value?.trim() === '') return true
            const regex = /^(?=(?:\D*\d){10,13}\D*$)[-( )(+44)(144)]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-.\s]*[-\0-9]{8,10} *$/
            return !(!value?.trim() || regex.test(value?.trim()) === false);
        }
    ),
    linkedin_profile_url: Yup.string().test(
        'Invalid url',
        'Invalid url, please add Linkedin url only',
        function (value) {
            if (value != '' && value != undefined && value != 'undefined') {
                    try {
                        let hostname = new URL(value).hostname
                    return (
                            hostname === 'linkedin.com' ||
                            hostname === 'www.linkedin.com'
                        )
                    } catch (error) {
                        return false
                    }
            } else {
                return true
            }
        }
    ),
}


const MyTextField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
      <>
        <Form.Label column sm="2">
        {label}
        </Form.Label>
        <Col sm="10">
            <Field className="form-control" id={field.name} name={field.name} placeholder={props.placeholder}/>
            {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
            ) : null}
        </Col>
      </>
    );
};

const tab_data_up = [
    { value: '', price: '' },
    { value: '', price: '' },
    { value: '', price: '' },
    { value: '', price: '' },
    { value: '', price: '' }
];

const tab_data_down = [
    { value: '', price: '' },
    { value: '', price: '' },
    { value: '', price: '' },
    { value: '', price: '' },
    { value: '', price: '' }
];

const TabDynamicContent = ({ current_tab }) => {
    var candiate_count
    var price
    if(current_tab == 1){
        candiate_count = '1,000'
        price = '100'
    }else if (current_tab == 2) {
        candiate_count = '10,000'
        price = '700'
    } else {
        candiate_count = '100,000'
        price = '4,000'
    }
    var text = 'Get <span className="cli_num">10,000</span> Client Leads Per Month and set up email automation for those leads and Dashboards'
    return (
      <>
        <div className="row mt-4">
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                {/* <a href='/scale_to_milions_details/?type=web_up'> */}
                    <div className="card text-white bg-primary">
                        <div className="card-body">
                            <p className="card-text">Allow Jobs, Resume Uploading/Parsing, Applicant Tracking, and Dashboards</p>
                            <p className="card-plan">$39/mo</p>
                        </div>
                    </div>
                {/* </a> */}
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                {/* <a href='/scale_to_milions_details/?type=mark_up'> */}
                    <div className="card text-white bg-success">
                        <div className="card-body">
                            <p className="card-text">Get <span className="cli_num">10,000</span> Client Leads Per Month and set up email automation for those leads and Dashboards</p>
                            <p className="card-plan">$400/mo</p>
                        </div>
                    </div>
                {/* </a> */}
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                {/* <a href='/scale_to_milions_details/?type=source_up'> */}
                    <div className="card text-white bg-info">
                        <div className="card-body">
                            <p className="card-text">Get <span className="cli_num">{candiate_count}</span> Candidate <br></br>Data per month</p>
                            <p className="card-plan">${price}/mo</p>
                        </div>
                    </div>
                {/* </a> */}
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                {/* <a href='/scale_to_milions_details/?type=coach_up'> */}
                    <div className="card text-white bg-dark">
                        <div className="card-body">
                            <p className="card-text">Learn from recruiter agency CEOs who have scaled to <span className="cli_num">$10MM/yr</span> revenue run-rate</p>
                            <p className="card-plan">$200/hr for Group</p>
                        </div>
                    </div>
                {/* </a> */}
            </div>
        </div>
        <div className="row mt-5 mb-5">
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                <p className="scale-heading">Website</p>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                <p className="scale-heading">Marketing</p>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                <p className="scale-heading">Sourcing</p>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                <p className="scale-heading">Coaching</p>
            </div>
        </div>
        <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                {/* <a href='/scale_to_milions_details/?type=web_dn'> */}
                    <div className="card text-white bg-primary">
                        <div className="card-body">
                            <p className="card-text">Niche Recruiting Website<br></br> Presence</p>
                            <p className="card-plan">$199/mo</p>
                        </div>
                    </div>
                {/* </a> */}
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                {/* <a href='/scale_to_milions_details/?type=mark_dn'> */}
                    <div className="card text-white bg-success">
                        <div className="card-body">
                            <p className="card-text">Calendaring and reading of emails and responding to emails</p>
                            <p className="card-plan">$300/mo</p>
                        </div>
                    </div>
                {/* </a> */}
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                {/* <a href='/scale_to_milions_details/?type=source_dn'> */}
                    <div className="card text-white bg-info">
                        <div className="card-body">
                            <p className="card-text">Overseas Sourcers to send you candidates while you sleep</p>
                            <p className="card-plan">$300/mo</p>
                        </div>
                    </div>
                {/* </a> */}
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                {/* <a href='/scale_to_milions_details/?type=coach_dn'> */}
                    <div className="card text-white bg-dark">
                        <div className="card-body">
                            <p className="card-text">Learn from recruiter agency CEOs who have scaled to <span className="cli_num">$10MM/yr</span> revenue run-rate</p>
                            <p className="card-plan">$1,000/hr for 1-to-1</p>
                        </div>
                    </div>
                {/* </a> */}
            </div>
        </div>
      </>
    );
};

const CheckBoxLabel = (props) => {
    return (
      <>
        <label className="form-check-label">
            <OverlayTrigger
                placement="right"
                trigger="hover"
                overlay={(
                <Popover>
                    <Popover.Title as="h3">
                    {props.title}
                    </Popover.Title>
                    <Popover.Content>
                    {props.content}
                </Popover.Content>
                </Popover>
                )}>
                <button>{props.label}</button>
            </OverlayTrigger>
        </label>
      </>
    );
};



const ScalePage = ({ver}) => {
    const validationSchema = Yup.object({ ...becomeRecruiterValidation })

    const [resume, setResume] = useState(null)
    const [resumeError, setResumeError] = useState(null)
    const [token, setToken] = useState('')
    const [key, setKey] = useState('first');


    const handleBecomeRecruiter = (data,resetForm) => {
        let URL = '/recruiter/create'
        let method = 'post'
        const payload = new FormData()
        payload.append('user[first_name]', data.first_name)
        payload.append('user[last_name]', data.last_name)
        payload.append('user[email]',data.email)
        payload.append('user[linkedin_profile_url]',data.linkedin_profile_url)
        payload.append('user[interested_into]',data.interested_into.toString())
        payload.append('user[phone_number]',data.phone_number)
        if(resume){
            payload.append('user[resume]',resume)
        }
        // if(token){
        //     payload.append('user[token]',token)
        // }
        payload.append('user[token]',token)
        console.log(token);
        makeRequest(URL,'post',payload , {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                if(responce['messageType'] == 'failure'){
                    return {
                        message: responce['msg'],
                        messageType: responce['messageType'],
                        loading: false,
                        autoClose: true,
                    }
                }else{
                    return {
                        message: `Become Recruiter Request Send Successfully.`,
                        messageType: 'success',
                        loading: false,
                        autoClose: true,
                    }
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: (responce) => {
                window.grecaptcha.reset();
                if(responce['data']['messageType'] == 'success'){
                    resetForm();
                }
                //window.location.href = '/cardinal/employees'
            },
        })
    }

    const handleToken = (token) => {
        setToken(token);
        setForm((currentForm) => {
         return {...currentForm, token }
        })
    }

    const handleExpire = () => {
        setForm((currentForm) => {
            return {...currentForm, token: null }
        })
    }
    
    return (
        <Wrapper>
            {/* <TeamSection> */}
                <div className="d-flex flex-column align-items-center pt-4">
                    <P size="40px" height="40px" maxWidth="100%">
                    Scale Your Recruiting Agency To Millions
                    </P>
                </div>
            {/* </TeamSection> */}
            <div className="formLayout">
                <div className="innerForm mb-3">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 "
                    fill
                    >
                    <Tab eventKey="first" title="$0 to $100K">
                        <TabDynamicContent current_tab={1} />
                    </Tab>
                    <Tab eventKey="second" title="$100K to $1MM">
                        <TabDynamicContent current_tab={2} />
                    </Tab>
                    <Tab eventKey="third" title="$1MM to $10M">
                        <TabDynamicContent current_tab={3} />
                    </Tab>
                    </Tabs>
                </div>
                <div className="innerForm">
                    <div className="row">
                        <div className="col-xl-8 col-lg-6 col-md-8 col-sm-10 mx-auto form">
                            <div className="d-flex flex-column">
                            { ver == 1 ? ( <P size="35px" height="40px" marginBottom="40px">Recruiter Form</P>) : 
                                    ( <P size="35px" height="40px" marginBottom="40px" maxWidth="100%" style={{ textAlign: 'center' }}>Scale to Millions Form</P> )}
                            </div>
                            <Formik
                                initialValues={{
                                    first_name: '',
                                    last_name: '',
                                    email: '', 
                                    linkedin_profile_url: '',
                                    phone_number: '',
                                    interested_into: [],
                                    resume: '',
                                }}
                                validateOnChange={false}
                                validateOnBlur={false}
                                validationSchema={validationSchema}
                                validate={(values) => {
                                    const errors = {}
                                    console.log(values)
                                    setResumeError('')
                                    if (
                                        !resume &&
                                        (values.linkedin_profile_url == '' ||
                                            values.linkedin_profile_url == 'undefined' ||
                                            values.linkedin_profile_url == undefined)
                                    ) {
                                        errors.resume = 'Resume Or Linkedin profile url is required'
                                        setResumeError(errors.resume)
                                    }
                                    return errors
                                }}
                                onSubmit={(values, { setSubmitting ,resetForm }) => {
                                    console.log(values)
                                    setTimeout(() => {
                                        setSubmitting(false);
                                        handleBecomeRecruiter(values,resetForm);
                                    }, 400);
                                }}
                            >
                                <FormSubmit>
                                    <Form.Group as={Row} className="mb-3" controlId="formFirstName">
                                        <MyTextField name="first_name" type="text" label="First Name*" placeholder="First Name"/>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formLastName">
                                        <MyTextField name="last_name" type="text" label="Last Name*" placeholder="Last Name"/>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                        <MyTextField name="email" type="text" label="Email*" placeholder="Email"/>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formNumber">
                                        <MyTextField name="phone_number" type="text" label="Phone Number*" placeholder="Phone Number"/>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formLinkdinUrl">
                                        <MyTextField name="linkedin_profile_url" type="text" label="Linkedin Profile Url*" placeholder="Linkedin Profile Url"/>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formLinkdinUrl">
                                    <FileButton
                                        type="button"
                                        label="Upload Resume"
                                        file='resume'
                                        resumeError={resumeError}
                                        setResumeError={setResumeError}
                                        getFile={(file) => setResume(file)}
                                    />
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formNumber">
                                        <Form.Label column sm="2">
                                        I Am Interested In:
                                        </Form.Label>
                                        <Col sm="10">
                                            {/* <Form.Check type="checkbox" id={`check-api-checkbox`}>
                                                <Field type="checkbox" name="interested_into" value="top_agency" />
                                                <CheckBoxLabel title="Top Recruiter" content="Top Recruiter" label="Top Recruiter" />
                                            </Form.Check>
                                            <Form.Check type="checkbox" id={`check-api-checkbox`}>
                                                <Field type="checkbox" name="interested_into" value="grow_my_agency" />
                                                <CheckBoxLabel title="Grow My Agency" content="Grow My Agency" label="Grow My Agency" />
                                            </Form.Check> */}
                                            <Form.Check type="checkbox" id={`check-api-checkbox`}>
                                                <Field type="checkbox" name="interested_into" value="become_recruiter" />
                                                <CheckBoxLabel title="Become A Recruiter" content="Become A Recruiter" label="Become A Recruiter" />
                                            </Form.Check>
                                            <Form.Check type="checkbox" id={`check-api-checkbox`}>
                                                <Field type="checkbox" name="interested_into" value="equity_bitcoin_cash_compensation" />
                                                <CheckBoxLabel title="Equity, Bitcoin, or Cash Compensation" content="Get equity, crypocurrency, or cash compensation from tier 1 startups." label="Equity, Bitcoin, or Cash Compensation" />
                                            </Form.Check>
                                            <Form.Check type="checkbox" id={`check-api-checkbox`}>
                                                <Field type="checkbox" name="interested_into" value="hourly_clients" />
                                                <CheckBoxLabel title="Hourly / Retained Clients" content="Get intros to new hourly / retained clients that want to interview you." label="Hourly / Retained Clients" />
                                            </Form.Check>
                                            <Form.Check type="checkbox" id={`check-api-checkbox`}>
                                                <Field type="checkbox" name="interested_into" value="contingency_clients" />
                                                <CheckBoxLabel title="Contingency Clients" content="Get rewards up to $50,000 for contingency placements.  Get in touch directly with hiring managers over video, phone, and email." label="Contingency Clients" />
                                            </Form.Check>
                                        </Col>
                                    </Form.Group>
                                    <ReCaptchaV2 
                                        sitekey='6Lf0VJIgAAAAADFu4GqYE5dILecwOI8w_-OutOde'
                                        onChange={handleToken}
                                        onExpire={handleExpire}
                                    />   
                                    <Row>
                                        <Col>
                                            <div style={{ marginTop: '18px' }}>
                                                <Button type="submit">Send</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </FormSubmit>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default ScalePage