import React from 'react'
import styles from './styles/ProfileAvatar.module.scss'
import { Dropdown, Image } from 'react-bootstrap';
import Util from '../../../utils/util'
import { capitalize } from '../../../utils'
const ProfileAvatar = ({profileAvatar, userId, organization_id, isEmployer, currentUser}) => {
  return(
    <Dropdown drop="down" className={styles.navDropdown}>
      <Dropdown.Toggle id="dropdown-basic" className={styles.profileAvatarDropdownButton}>
          <span className={styles.userName}>Welcome  { (currentUser.first_name) ? capitalize(currentUser.first_name) : currentUser.email }</span>&nbsp;
          {profileAvatar ?
            <Image src={profileAvatar} alt="Avatar" className={styles.avatar} />:
            <div className={styles.candidateAvtarImage}>{ (currentUser.first_name) ? (currentUser?.first_name && Util.getFirstCharater(currentUser?.first_name)) + (currentUser?.last_name && Util.getFirstCharater(currentUser?.last_name) ) : (currentUser?.email && Util.getFirstCharater(currentUser?.email) ) }</div>
          }
          <Dropdown.Menu align="right">
            {/* <Dropdown.Item href={`/users/profile/${userId}`}>View Profile</Dropdown.Item> */}
            {currentUser.role == 'ct_recruiter' && <Dropdown.Item href={"/my_profile"}>Update Profile</Dropdown.Item> }
            {currentUser.role != 'ct_recruiter' && <Dropdown.Item href={"/account/setting"}>Account Settings</Dropdown.Item> }
            {/*<Dropdown.Item href='/subscription'>Subscriptions & Billing</Dropdown.Item>*/}
            <Dropdown.Item onClick={signOut}> Log Out</Dropdown.Item>
          </Dropdown.Menu>
      </Dropdown.Toggle>
    </Dropdown>
  )

  function signOut(){
    localStorage.removeItem("user")

    fetch('/users/sign_out', {method: 'DELETE'})
    .then((res) => {
      window.location.href = '/users/sign_in'
    })
  }
}

export default ProfileAvatar;
