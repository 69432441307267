// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Policy-module__policyContainer___2sJ66{padding:5rem 10rem}@media (max-width: 500px){.Policy-module__policyContainer___2sJ66{padding:1rem 2rem}}span{line-height:1.6;text-align:justify}@media only screen and (min-device-width: 320px) and (max-device-width: 767px){p{margin-left:10px !important;text-align:justify !important}ol li{margin-left:0px !important}.Policy-module__policyContainer___2sJ66{padding:1rem 2rem}ul li{margin-left:0px !important}}\n", "",{"version":3,"sources":["/home/siva/workspace/cardinal-recruiter/releases/20240520115144/app/javascript/components/pages/PolicyPage/styles/Policy.module.scss"],"names":[],"mappings":"AAAA,wCACI,kBAAmB,CACtB,0BAGG,wCACI,iBAAkB,CACrB,CAGL,KACI,eAAgB,CAChB,kBAAmB,CACtB,+EAEG,EACI,2BAA2B,CAC3B,6BAA8B,CACjC,MAEG,0BAA0B,CAC7B,wCAEG,iBAAkB,CACrB,MAEG,0BAA2B,CAC9B","file":"Policy.module.scss","sourcesContent":[".policyContainer {\n    padding: 5rem 10rem;\n}\n\n@media (max-width: 500px) {\n    .policyContainer {\n        padding: 1rem 2rem;\n    }\n}\n\nspan{\n    line-height: 1.6;\n    text-align: justify;\n}\n@media only screen and (min-device-width : 320px) and (max-device-width : 767px){\n    p{\n        margin-left:10px !important;\n        text-align: justify !important;\n    }\n    ol li{\n        margin-left:0px !important;\n    }\n    .policyContainer {\n        padding: 1rem 2rem;\n    }\n    ul li{\n        margin-left: 0px !important;\n    }\n}"]}]);
// Exports
exports.locals = {
	"policyContainer": "Policy-module__policyContainer___2sJ66"
};
module.exports = exports;
