import React from 'react'
import {Row ,Col ,Container} from 'react-bootstrap'


const RecruiterBusinessPage = ({currentUser}) => {

   const blockClick = () => {
      window.open(
         'https://cardinaltalent.ai/',
         '_blank'
      );
   }

    const paymentClick = () => {
      // window.open(
      //     '/subscription/order',
      // );
      window.open(
         'https://cardinaltalent.ai/',
         '_blank'
      );
  }

    return (
        <Container fluid>
          <Row className="justify-content-md-center mt-3 mb-5">
            <Col xs lg={12} md={12}>
              <h2 className='text-center font-weight-bold'>Start your Own 6,7, or 8 Figure Recruiting Business from Scratch</h2>
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-3 mb-5 m-1">
            <Col xs lg={4} className='w-100' style={{background: '#7fd335'}}>
               <h3 className='text-center font-weight-bold' style={{padding: '7px 5px 0px 5px'}} onClick={paymentClick}>$199/mo</h3>
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-3 mb-5">
            <Col xs lg={3} className='w-100 bg-dark m-3 d-flex align-items-center' style={{padding: '15px'}} onClick={blockClick}>
               <h3 className='text-center text-light font-weight-bold' style={{padding: '30px'}}>AI Enabled Bulk Email System with Spam Filter Avoidance Technology</h3>
            </Col>
            <Col xs lg={3} className='w-100 m-3 d-flex align-items-center' style={{background: '#e450ff'}} onClick={blockClick}>
               <h3 className='text-center text-light font-weight-bold' style={{padding: '30px'}}>100s of Jobs with Rewards up to $30,000 per hire</h3>
            </Col>
            <Col xs lg={3} className='w-100 bg-dark m-3 d-flex align-items-center' style={{padding: '15px'}} onClick={blockClick}>
               <h3 className='text-center text-light bg-dark font-weight-bold' style={{padding: '30px'}}>Live Courses & Mentorship on how to land your own recruiting clients and start your own firm!</h3>
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-3 mb-5">
            <Col xs lg={3} className='w-100 m-3 d-flex align-items-center' style={{background: '#3a9de3'}} onClick={blockClick}>
               <h3 className='text-center font-weight-bold' style={{padding: '30px'}}>AI Enabled Applicant Tracking and Sourcing System</h3>
            </Col>
            <Col xs lg={3} className='w-100 bg-dark m-3 d-flex align-items-center' style={{padding: '15px'}} onClick={blockClick}>
               <h3 className='text-center text-light bg-dark font-weight-bold' style={{padding: '30px'}}>1,000 Email Addresses / phone numbers per month of clients / candidates</h3>
            </Col>
            <Col xs lg={3} className='w-100 m-3 d-flex align-items-center' style={{background: '#7fd335'}} onClick={blockClick}>
               <h3 className='text-center font-weight-bold' style={{padding: '30px'}}>Legal Agreements for Hourly, Contingency and Retained Search</h3>
            </Col>
          </Row>
        </Container>
        // <section className="about-area" style={{paddingBottom: '30px'}}>
        //     <div className="container">
        //         <div className="row align-items-center justify-content-between">
        //             <div className="col-md-6 col-sm-12 col-12">
        //                 <Button
        //                     onClick={() =>
        //                         (window.location.href = '/promotion/plan/monthly')
        //                     }
        //                 >
        //                     Buy Now
        //                 </Button>
        //             </div>
        //             <div className="col-md-6 col-sm-12 col-12">
        //                 <Button
        //                     onClick={() =>
        //                         (window.location.href = '/promotion/plan/personalized')
        //                     }
        //                     marginLeft={15}
        //                 >
        //                     Buy Now
        //                 </Button>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}

export default RecruiterBusinessPage
