import React, { useState, useEffect } from 'react'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ImageOne from '../../../../assets/images/recruiter_page_assets/buy.jpg'
import RightArrow from '../../../../assets/images/recruiter_page_assets/arrow-right-long.svg'
import './styles/Subscription.scss'
import {
  H1,
  P,
  Button,
  CONTAINER,
  GRID,
  COL,
  A,
} from './styles/Subscription.styled'
import TextInput from '../../common/TextInput/TextInput'
import { Formik, Form } from 'formik'
import CheckoutForm from './CheckoutForm'
import { Elements, StripeProvider } from 'react-stripe-elements'
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import SweetAlert from 'react-bootstrap-sweetalert';

const OrderPage = (props) => {
  const { publishable_key, plans, current_user, billing_info, active_plan } = props
  const [step1, setStep1] = useState(false)
  const [step2, setStep2] = useState(true)
  const [loading, setLoading] = useState(false)
  const [activePlanDetail, setActivePlanDetail] = useState(plans[0])
  const [stripeStatus, setStripeStatus] = useState([])
  const [stripeErrorMessage, setStripeErrorMessage] = useState('')
  const [sweetalertMessage, setSweetalertMessage] = useState('')
  const setActivePlan = (plan_id) => {
    setActivePlanDetail((plans?.filter((p) => p.id == plan_id))[0])
  }
  // setActivePlanDetail(plans[0])
  const calculateDiscountAmount = (amount, per) => {
    if (amount > 0) {
      return parseFloat((activePlanDetail?.amount * activePlanDetail?.discount) / 100).toFixed(2)
    } else {
      return 0
    }
  }

  const paymentSuccess = () => {
    window.location.href = '/subscription'
  }

  const createSubscription = (values, stripe_payload) => {
    let URL = `/subscription/payment`
    const payload = new FormData()
    payload.append('subscription[first_name]', values.firstName)
    payload.append('subscription[last_name]', values.lastName)
    payload.append('subscription[email]', values.email)
    payload.append('subscription[phone_number]', values.phoneNumber)
    payload.append('subscription[country]', values.country)
    payload.append('subscription[state]', values.state)
    payload.append('subscription[city]', values.city)
    payload.append('subscription[zipcode]', values.zipcode)
    payload.append('subscription[user_id]', (current_user == undefined) ? '' : current_user?.id)
    payload.append('subscription[plan_id]', activePlanDetail.id)
    payload.append('subscription[address]', values.address1)
    payload.append('subscription[address_line_1]', values.address2)
    payload.append('subscription[name_card]', values?.nameCard || '')
    payload.append('subscription[token]', (stripe_payload?.token) ? JSON.stringify(stripe_payload.token) : '')
    payload.append('subscription[billing_info_id]', billing_info?.id || '')
    makeRequest(URL, 'post', payload, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createResponseMessage: (responce) => {
        if (responce.success) {
          setSweetalertMessage('Your Subscription is Activated Now.')
        } else {
          setLoading(false)
          return {
            message: responce.message,
            messageType: 'failure',
            loading: false,
            autoClose: true,
          }
        }
      },
      createErrorMessage: (e) => {
        setLoading(false)
        return e.message
      },
    })
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    if (activePlanDetail?.amount > 0) {
      stripeStatus.createToken({ name: 'ct_customer' }).then(stripe_payload => {
        if (stripe_payload.token) {
          createSubscription(values, stripe_payload)
        }
        if (stripe_payload.error) {
          setLoading(false)
          setStripeErrorMessage(stripe_payload.error.message)
        }
      });
    } else {
      createSubscription(values, '')
    }
  };

  const validationSchemaWithCard = Yup.object({
    firstName: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
      .max(15, 'Must be 15 characters or less')
      .required('First Name is Required'),
    lastName: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
      .max(15, 'Must be 20 characters or less')
      .required('Last Name is Required'),
    phoneNumber: Yup.string().trim()
      .matches(/^[0-9]+$/, "Please enter valid phone number")
      .max(15, 'Must be 14 characters or less')
      .min(10, "Please enter a number more than 10 digit")
      .required('Phone Number is Required'),
    // country: Yup.string().trim()
    //   .matches(/^[A-Z]+$/, "Only alphabet characters are enter here")
    //   .max(2, 'Must be 2 characters')
    //   .min(2, 'Must be 2 characters')
    //   .required('Country Name is Required'),
    state: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
      .required('State Name is Required'),
    city: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
      .required('City Name is Required'),
    zipcode: Yup.string().trim()
      .matches(/^[0-9]+$/, "Please enter valid zip code")
      .max(6, 'Must be 6 characters or less')
      .min(5, "Zip Code can have 5 or 6 digits only")
      .required('Zip Code is Required'),
    address1: Yup.string().trim()
      .required('Address1 is Required'),
    address2: Yup.string().trim()
      .required('Address2 is Required'),
    nameCard: Yup.string().trim()
      .matches(/^[a-zA-Z ]+$/, "Please enter alphabets only")
      .required('Card Name is Required'),
    email: Yup.string().trim()
      .email('Invalid email address')
      .required('Email is Required')
  })

  const validationSchemaWithoutCard = Yup.object({
    firstName: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Please enter alphabets only")
      .max(15, 'Must be 15 characters or less')
      .required('First Name is Required'),
    lastName: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Please enter alphabets only")
      .max(15, 'Must be 20 characters or less')
      .required('Last Name is Required'),
    phoneNumber: Yup.string().trim()
      .matches(/^[0-9]+$/, "Please enter valid phone number")
      .max(15, 'Must be 14 characters or less')
      .min(10, "Phone Numbers can have 10 to 15 digits only")
      .required('Phone Number is Required'),
    // country: Yup.string().trim()
    //   .matches(/^[A-Z]+$/, "Only alphabet characters are enter here")
    //   .max(2, 'Must be 2 characters')
    //   .min(2, 'Must be 2 characters')
    //   .required('Country Name is Required'),
    state: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
      .required('State Name is Required'),
    city: Yup.string().trim()
      .matches(/^[a-zA-Z]+$/, "Only alphabet characters are enter here")
      .required('City Name is Required'),
    zipcode: Yup.string().trim()
      .matches(/^[0-9]+$/, "Please enter valid zip code")
      .max(6, 'Must be 6 characters or less')
      .min(5, "Zip Code can have 5 or 6 digits only")
      .required('Zip Code is Required'),
    email: Yup.string().trim()
      .email('Invalid email address')
      .required('Email is Required'),
    address1: Yup.string().trim()
      .required('Address1 is Required'),
    address2: Yup.string().trim()
      .required('Address2 is Required'),
  })


  return (
    <>
      {step1 && (
        <>
          <section>
            <Row className="mx-0 mt-5">
              <Col className="d-flex flex-column flex-wrap align-items-end justify-content-center mb-2 mr-5">
                <div className="find-details">
                  <H1 className="find-tittle">
                    Simple, transparent pricing.
                  </H1>
                  <P>
                    Always know what you’ll pay.
                  </P>
                </div>
              </Col>
              <Col className="pr-0 d-flex">
                <Image src={ImageOne} className="img-fluid" />
              </Col>
            </Row>
          </section>
          <section className="check-out">
            <CONTAINER>
              <GRID>
                <Row>
                  {plans && plans?.length ? (
                    plans?.map((plan) => {
                      if (plan)
                        return (
                          <COL key={plan.id} className="col-sm-6 col-12 col-md-6 col-lg-6">
                            <div className='requeir-details'>
                              <H1 className="find-tittle">
                                ${plan?.amount}<small style={{ fontSize: '30%' }}>/month</small>
                              </H1>

                              <P color="#1D2447">{plan?.name}</P>
                              {
                                ((plan?.amount == 0 && !active_plan) || active_plan?.subscription_plan_id == plan?.id) ?
                                  <A href="#" style={{ background: 'green' }}>
                                    Current Active Plan

                                  </A>
                                  :
                                  <A href="#" onClick={(e) => {
                                    setStep2(true)
                                    setStep1(false)
                                    setActivePlan(plan.id)
                                  }}>
                                    Buy Now
                                    <Image
                                      src={RightArrow}
                                      style={{ marginLeft: '15px' }}
                                    />
                                  </A>
                              }

                              <b className='mt-2'>Plan Detail: </b>
                              <P
                                marginTop={10}
                                weight={300}
                                size={16}
                                height={22}
                                center
                              >
                                <div dangerouslySetInnerHTML={{ __html: plan?.description }} />
                              </P>

                            </div>
                          </COL>
                        )
                    })
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      No Plan Available
                    </div>
                  )}
                </Row>
              </GRID>
            </CONTAINER>
          </section>
        </>
      )}

      {step2 && (
        <>
          <section>
            <Row className="main-find">
              <Col>
                <div className="m-5 form-details">
                  <h3 className="find-tittle text-center">
                    BILLING INFORMATION
                  </h3>
                  <hr></hr>
                  <div className={'signForm'}>
                    <Formik
                      validateOnChange={true}
                      validateOnBlur={true}
                      initialValues={{
                        firstName: billing_info?.first_name || '',
                        lastName: billing_info?.last_name || '',
                        email: billing_info?.email || '',
                        phoneNumber: billing_info?.phone_number || '',
                        country: billing_info?.country || 'US',
                        state: billing_info?.state || '',
                        city: billing_info?.city || '',
                        zipcode: billing_info?.zipcode || '',
                        address1: billing_info?.address || '',
                        address2: billing_info?.address_line_1 || '',
                        nameCard: ''
                      }}
                      validationSchema={(activePlanDetail?.amount > 0) ? validationSchemaWithCard : validationSchemaWithoutCard}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      <Form>
                        <Row className='subScriptionForm'>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="First Name*"
                              name="firstName"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Last Name*"
                              name="lastName"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Email Address*"
                              name="email"
                              type="email"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Phone Number*"
                              name="phoneNumber"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Address1*"
                              name="address1"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Address2*"
                              name="address2"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="City*"
                              name="city"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="State*"
                              name="state"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              as="select"
                              className={'fullwidthInput'}
                              label="Country Code*"
                              name="country"
                              type="text"
                              width="100%"
                              display="block"
                            >
                              <option value="US">US</option>
                            </TextInput>
                          </Col>
                          <Col lg={6} md={6} sm={12} xs={12}>
                            <TextInput
                              className={'fullwidthInput'}
                              label="Zip Code*"
                              name="zipcode"
                              type="text"
                              width="100%"
                              display="block"
                            />
                          </Col>
                          {(activePlanDetail?.amount > 0) &&
                            <>
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <TextInput
                                  className={'fullwidthInput'}
                                  label="Name on Card*"
                                  name="nameCard"
                                  type="text"
                                  width="100%"
                                  display="block"
                                />
                              </Col>
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <label>Enter Card Detail*</label>
                                <StripeProvider apiKey={publishable_key}>
                                  <Elements>
                                    <CheckoutForm setStripeStatus={setStripeStatus} />
                                  </Elements>
                                </StripeProvider>
                                <small style={{ color: '#eb1c26' }}>{(stripeErrorMessage) && stripeErrorMessage}</small>
                              </Col>
                            </>
                          }
                          <div style={{ marginTop: '10px' }}>
                            <Button
                              className={'sign_in_button'}
                              type="submit"
                              style={{
                                alignSelf: 'flex-end',
                                float: 'left',
                              }}
                              disabled={loading}
                            >
                              {loading ? (
                                <Spinner animation="border" variant="light" />
                              ) : (
                                (activePlanDetail?.amount > 0) ? 'Payment' : 'Submit'
                              )}
                            </Button>
                            {/* <Button disabled={loading} className='ml-2' onClick={(e) => {
                              setStep2(false)
                              setStep1(true)
                            }}>
                              Cancel
                            </Button> */}
                          </div>
                        </Row>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </Col>
              <Col className="m-5">
                <div className="find-details">
                  <h3 className="find-tittle text-center">
                    ORDER SUMMARY
                  </h3>
                  <hr></hr>
                  <div className="d-flex flex-wrap justify-content-between">
                    <P color="#1D2447">{activePlanDetail?.name}</P>
                    <P color="#1D2447">${activePlanDetail?.amount}</P>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between">
                    <small className='plan_description' color="#1D2447">
                      <div dangerouslySetInnerHTML={{ __html: activePlanDetail?.description }} />
                    </small>
                  </div>

                  <hr></hr>
                  <div className="d-flex flex-wrap justify-content-between">
                    <P color="#1D2447">SUBTOTAL</P>
                    <P color="#1D2447">${activePlanDetail?.amount}</P>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between">
                    <P color="#1D2447">DISCOUNT ({activePlanDetail?.discount}%)</P>
                    <P color="#1D2447">${calculateDiscountAmount(activePlanDetail?.amount, activePlanDetail?.discount)}</P>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between">
                    <P color="#1D2447">TAX</P>
                    <P color="#1D2447">$0.00</P>
                  </div>
                  <hr></hr>
                  <div className="d-flex flex-wrap justify-content-between">
                    <P color="#1D2447">TOTAL</P>
                    <P color="#1D2447">${activePlanDetail?.amount - calculateDiscountAmount(activePlanDetail?.amount, activePlanDetail?.discount)}</P>
                  </div>
                  <br></br>
                  <div className='teams_condition'>
                    <small color="#1D2447">SSL & SCA Ready Secure Payment.</small><br></br>
                    <small color="#1D2447">All Amounts are in US. Dollars.</small><br></br> 
                    <small color="#1D2447">By Placing this order, You agree to our Terms & Conditions</small>
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </>
      )}

      {sweetalertMessage != '' && (
        <SweetAlert success confirmBtnText="Goto Subscription" title="Success" onConfirm={paymentSuccess}>
          {sweetalertMessage}
        </SweetAlert>
      )}
    </>
  )
}
export default OrderPage
