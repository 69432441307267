import React from 'react'

import TextInput from '../../common/TextInput/TextInput'
import styles from './styles/Signup.module.scss'
import { FormWrapper } from './styles/Signup.styled'
import {
  StyledForm,
} from '../../pages/CreateProfilePage/styles/CreateProfilePage.styled'

function UserForm(props) {
  return (
    <FormWrapper>
      {props.signUp && (
      <div className={styles.mainoragiForm}>
        <TextInput
            className={styles.contactDetailInpu}
            label="First Name*"
            name="firstName"
            type="text"
            id="firstName"
            width="20rem"
            maxLength={50}
        />
        <TextInput
            className={styles.contactDetailInpu}
            label="Last Name*"
            name="lastName"
            type="text"
            id="lastName"
            width="20rem"
            maxLength={50}
            style={{
                marginLeft: '10px',
            }}
        />
        
      </div>
      
      )}
      {props.signUp && (
      <div className={styles.mainoragiForm}>
        <TextInput
            className={styles.contactDetailInpu}
            label="Company Name*"
            name="companyName"
            type="text"
            id="companyName"
            width="20rem"
            maxLength={50}
        />
        <TextInput
            className={styles.contactDetailInpu}
            label="Phone Number*"
            name="phoneNumber"
            type="text"
            id="phoneNumber"
            width="20rem"
            maxLength={50}
            style={{
                marginLeft: '10px',
            }}
        />
        
      </div>
      
      )}
      <TextInput
        className={styles.fullwidthInput}
        label="Email Address*"
        name="email"
        type="email"
        width="28rem"
      />
      <TextInput
        className={styles.fullwidthInput}
        label="Password*"
        name="password"
        type="password"
        width="28rem"
      />
    </FormWrapper>
  )
}

export default UserForm
