import React from 'react'
import _ from 'lodash'

import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import GoogleIcon from '../../../../assets/images/icons/icon-google.svg'
import styles from './styles/Signup.module.scss'
import { H1, P, A, GoogleButton } from './styles/Signup.styled'
import SignUp from './SignUp'

const CreateAccountPage = () => {
  const exactUrl = window.location.href
  const userRole = exactUrl.split('/').pop()

  const handleGoogleSignIn = async () => {
    window.location.href = `/user/auth/google_oauth2?role=${userRole}`
  }

  return (
    <>
      <div className={`${styles.mainForm}`}>
        <MainPanel>
          <H1>Create an Account</H1>
          <div className={styles.formContainer}>
            <SignUp userRole={userRole} />
            {/* <GoogleButton
              onClick={() => handleGoogleSignIn()}
            >
              <img src={GoogleIcon} />
              Continue with Google
            </GoogleButton> */}
            <div className="d-flex flex-row mt-3">
              <P>Already have an account?</P>
              <A href="/users/sign_in">Sign In</A>
            </div>
          </div>
        </MainPanel>
        {/* <InfoPanel>
          <div className={`${styles.infopanelDiv}`}>
            <div className="d-flex flex-column ">
              <P>Have an account already?</P>
              <A href="/users/sign_in">Sign In</A>
            </div>
          </div>
        </InfoPanel> */}
      </div>
    </>
  )
}

export default CreateAccountPage
