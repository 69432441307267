import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'
import { Spinner, Button, Alert } from 'react-bootstrap'
import ReCaptchaV2 from 'react-google-recaptcha'
import styles from './styles/Signup.module.scss'
import UserForm from './UserForm'
import TextInput from '../../common/TextInput/TextInput'
import { Label, GoogleButton } from './styles/Signup.styled'
import GoogleIcon from '../../../../assets/images/icons/icon-google.svg'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phoneNumber: '',
  companyName: '',
  agreeToTermsAndPolicy: false,
}

function SignUp({ userRole, signUpWithRole }) {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [role, setRole] = useState(userRole)
  const [token, setToken] = useState('')

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('First name is required')
      .test(
        'first name alphabets only',
        'First Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    lastName: Yup.string()
      .required('Last Name is required')
      .test(
        'last name alphabets only',
        'Last Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    phoneNumber: Yup.string().required('Phone Number is required').test(
        'Invalid Number',
        "Please enter valid phone number[ Either 10 digit number or '+' and up to 13 digit numbers ].",
        function (value) {
            if (value?.trim() === '') return true
            const regex = /^(?=(?:\D*\d){10,13}\D*$)[-( )(+44)(144)]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-.\s]*[-\0-9]{8,10} *$/
            return !(!value?.trim() || regex.test(value?.trim()) === false);
        }
    ),
    companyName: Yup.string()
      .required('Company Name is required'),
    email: Yup.string({ role })
      .email()
      .required('Email is required')
      .test(
        'email-unique',
        'This email is already in use',
        async function (value) {
          const res = await axios.get(
            `/users/exists?email=${encodeURIComponent(value)}`
          )
          return !res.data.user_exists
        }
      ),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,25}$/,
        'Password should have 8 to 25 characters, must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
      ),
    agreeToTermsAndPolicy: Yup.boolean().oneOf(
      [true],
      'Please accept the Terms and Conditions & Privacy Policy'
    ),
  })

  const handleSubmit = async (user) => {
    const payload = new FormData()
    const url = `/signup/${role}`
    if (!_.isEmpty(role)) {
      payload.append('user[role]', role)
      payload.append('user[first_name]', user.firstName)
      payload.append('user[last_name]', user.lastName)
      payload.append('user[phone_number]', user.phoneNumber)
      payload.append('user[company_name]', user.companyName)
      payload.append('user[email]', user.email)
      payload.append('user[password]', user.password)
      payload.append('user[password_confirmation]', user.password)
      payload.append('user[token]', token)
      console.log(payload)
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      setLoading(true)
      await axios
        .post(url, payload, {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
          },
          role,
        })
        .then((res) => {
          const responce = res.data
          if (responce.success) {
            setResponse({ messageType: 'success', message: responce.msg })
            window.location.href = '/'
          } else {
            setResponse({ messageType: 'danger', message: responce.msg })
          }
          setLoading(false)
        })
        .catch((e) => {
          setResponse({ messageType: 'success', message: e })
          setLoading(false)
        })
    } else {
      setResponse({ messageType: 'danger', message: 'Please Select Role' })
    }
  }

  const handleGoogleSignIn = () => {
    if (_.isEmpty(role)) {
      setResponse({ messageType: 'danger', message: 'Please Select Role' })
    } else {
      window.location.href = `/user/auth/google_oauth2?role=${role}`
    }
  }

  const handleRole = (event) => {
    setResponse({})
    setRole(event.target.value)
  }

  const handleToken = (token) => {
      setToken(token);
      setForm((currentForm) => {
      return {...currentForm, token }
      })
  }

  const handleExpire = () => {
      setForm((currentForm) => {
          console.log(currentForm)
          return {...currentForm, token: null }
      })
  }

  return (
    <div>
      {!_.isEmpty(response) && (
        <Alert
          variant={response.messageType}
          onClose={() => setResponse({})}
          style={{ fontSize: '15px', width: '27rem' }}
          dismissible
          className="candidate-info-close"
        >
          <span className={styles.alertInfo}> {response.message} </span>
        </Alert>
      )}
      {signUpWithRole ? (
        <>
          <ChooseRole role={role} handleRole={handleRole} />
          <GoogleButton
            onClick={() => handleGoogleSignIn()}
          >
            <img src={GoogleIcon} />
            Continue with Google
          </GoogleButton>
        </>
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          <Form className={styles.signForm}>
            <UserForm signUp={true} />
            <TextInput
              type="checkbox"
              name="agreeToTermsAndPolicy"
              id="terms_and_policy"
              label={<span> I agree to the <a href='/terms_of_service' target='_blank'>Terms and Conditions</a> & <a href='/privacy_policy' target='_blank'>Privacy Policy</a></span>}
            />
            <div style={{ marginBottom: '10px'}}>
              <ReCaptchaV2 
                  sitekey='6Lf0VJIgAAAAADFu4GqYE5dILecwOI8w_-OutOde'
                  onChange={handleToken}
                  onExpire={handleExpire}
              />
            </div>
            <Button
              type="submit"
              className={styles.submitButton}
              disabled={loading}
            >
              {loading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                'Sign Up'
              )}
            </Button>
          </Form>
        </Formik>
      )}
    </div>
  )
}

export const ChooseRole = ({ handleRole, role }) => {
  return (
    <div className={styles.responseContainer}>
      <div>
        <Label>Choose Role to Sign Up</Label>
      </div>
      <div className={styles.roleContainer}>
        <div>
          <input
            type="checkbox"
            value="employer"
            onChange={handleRole}
            checked={role === 'employer'}
          />
          <span className={styles.roleText}>Employer</span>
        </div>
        <div>
          <input
            type="checkbox"
            value="recruiter"
            onChange={handleRole}
            checked={role === 'recruiter'}
          />
          <span className={styles.roleText}>Recruiter</span>
        </div>
        <div>
          <input
            type="checkbox"
            value="talent"
            onChange={handleRole}
            checked={role === 'talent'}
          />
          <span className={styles.roleText}>Talent</span>
        </div>
      </div>
    </div>
  )
}

export default SignUp
