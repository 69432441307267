import axios from 'axios';

export const getOrganizations = async () => {
  const url = '/recruiter_organizations';

  const CSRF_Token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  try {
    const response = await axios.get(`${url}`, {
      headers: {
        'content-type': 'application/json',
        'X-CSRF-Token': CSRF_Token,
      },
    });
    const properOrgnizations = response.data.recruiter_organizations?.filter(
      (organization) => organization !== null
    );
    const recruiterOrganizationsArray = properOrgnizations?.map(
      (recruiterOrganization) => {
        return {
          value: recruiterOrganization?.id,
          label: recruiterOrganization?.name,
        };
      }
    );
    return recruiterOrganizationsArray;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
};
