import React, { useState } from 'react'
import { useField , Formik, Field ,Form as FormSubmit } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import {Button } from '../Admin/styles/UserManagementEditPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col, Form} from 'react-bootstrap'
import './styles/BecomeRecuirter.scss'
import {
    Wrapper,
    TeamSection,
    P
} from './styles/BecomeRecuirterPage.styled'

const ScaleDetailPage = ({ver}) => {
    
    
    return (
        <Wrapper>
            <TeamSection>
                <div className="d-flex flex-column align-items-center">
                    <P size="40px" height="40px">
                        Under Developement
                    </P>
                </div>
            </TeamSection>
        </Wrapper>
    )
}
export default ScaleDetailPage