import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import './styles/SubscriptionPlan'
import Button from '../../Admin/shared/Button'
import { CKEditor } from 'ckeditor4-react';
import { ExitToApp } from '@material-ui/icons'

const SubscriptionPlanAdd = ({ subscriptionEntry, onHide, method, fetchData }) => {
  const [validationError, setValidationError] = useState({})
  const [descriptionAdd, setDescriptionAdd] = useState(subscriptionEntry?.description || '')
  const [subscriptionStatus, setSubscriptionStatus] = useState([
    { label: 'Active', value: 'active' },
    { label: 'In Active', value: 'in_active' },
    { label: 'Hold', value: 'hold' },
  ])
  const [subscriptionRole, setSubscriptionRole] = useState([
    { label: 'Employer', value: 'employer' },
    { label: 'Recruiter', value: 'recruiter' },
  ])
  const [modal, setModal] = useState('')
  const [selectedStatus, setSelectedStatus] = useState( (subscriptionEntry?.status) ? subscriptionStatus.filter((w) => w.value == subscriptionEntry.status)[0] : '')
  const [selectedRole, setSelectedRole] = useState( (subscriptionEntry?.role) ? subscriptionRole.filter((w) => w.value == subscriptionEntry.role)[0] : '')
  const [entryData, setEntryData] = useState({
    name: subscriptionEntry?.name ? subscriptionEntry?.name : '',
    description: subscriptionEntry?.description ? subscriptionEntry?.description : '',
    amount: subscriptionEntry?.amount ? subscriptionEntry?.amount : 0,
    discount: subscriptionEntry?.discount ? subscriptionEntry?.discount : '',
    candidate_limit: subscriptionEntry?.candidate_limit ? subscriptionEntry?.candidate_limit : '',
    status: subscriptionEntry?.status ? subscriptionEntry?.status : '',
    jobs_limit: subscriptionEntry?.jobs_limit ? subscriptionEntry?.jobs_limit : '',
    role: subscriptionEntry?.role ? subscriptionEntry?.role : '',
  })

  const [btnDisabled, setBtnDisabled] = useState(false)
  const handleChange = (e) => {
    setValidationError({})
  }
  useEffect(() => {
    setModal(document.querySelector('.modal'))
  })

  const handleSubmit = (e) => {
    e.preventDefault()    
    setBtnDisabled(true)
    if (validateForm(e)) {
      let URL = '/admin/cr_subscription_plans'
      if (method === 'put') {
        URL = `/admin/cr_subscription_plans/${subscriptionEntry.id}`
      }
      const payload = new FormData()
      payload.append('subscription_plan[name]', e?.target?.name?.value?.trim())
      payload.append('subscription_plan[description]', descriptionAdd)
      payload.append('subscription_plan[amount]', e?.target?.amount?.value?.trim())
      payload.append('subscription_plan[discount]', e.target.discount?.value || 0)
      // payload.append('subscription_plan[candidate_limit]', e.target.candidate_limit.value)
      payload.append('subscription_plan[status]', e.target.status.value)
      // payload.append('subscription_plan[jobs_limit]', e.target.jobs_limit.value)
      // payload.append('subscription_plan[role]', e.target.role.value)
      //headers
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      const headers = {
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
        },
      }
      makeRequest(URL, method, payload, headers, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
      }).then((res) => {
        if (res.data.success) {
          onHide()
          fetchData()
        }
      })
    }
    setBtnDisabled(false)
  }

  const validateForm = (e) => {
    let errors = {}
    let formIsValid = true
    const regex = /^[A-Za-z][A-Za-z0-9_ ]{2,29}$/
    if (!e?.target?.name?.value?.trim()) {
      formIsValid = false;
      errors["name"] = "Plan Name is required field."
    } else if (!regex.test(e?.target?.name?.value?.trim())) {
      formIsValid = false;
      errors["name"] = "Please Enter Valid Plan Name."
    }
    if (!descriptionAdd?.trim()) {
      formIsValid = false;
      errors["description"] = "Description is required field."
    }
    if (!e?.target?.amount?.value?.trim()) {
      formIsValid = false;
      errors["amount"] = "Amount is required field."
    }else if(e?.target?.amount?.value < 0){
      formIsValid = false;
      errors["amount"] = "Amount must be greater than or equal to 0."
    }
    
    // if (!e?.target?.candidate_limit?.value?.trim()) {
    //   formIsValid = false;
    //   errors["candidate_limit"] = "Candidate Limit is required field."
    // }else if(e?.target?.candidate_limit?.value < 0){
    //   formIsValid = false;
    //   errors["candidate_limit"] = "Candidate Limit must be greater than or equal to 0."
    // }

    if (!e?.target?.status?.value?.trim()) {
      formIsValid = false;
      errors["status"] = "Status is required field."
    }

    // if (!e?.target?.jobs_limit?.value?.trim()) {
    //   formIsValid = false;
    //   errors["jobs_limit"] = "Active Jobs Limit is required field."
    // }else if(e?.target?.candidate_limit?.value < 0){
    //   formIsValid = false;
    //   errors["jobs_limit"] = "Active Jobs Limit must be greater than or equal to 0."
    // }

    // if (!e?.target?.role?.value?.trim()) {
    //   formIsValid = false;
    //   errors["role"] = "Role is required field."
    // }
    setValidationError(errors)
    setBtnDisabled(formIsValid)
    return formIsValid;
  }

  const handleEditorChange = (content) => {
    setDescriptionAdd(content.editor.getData())
    setValidationError('')
  }

  return (
    <>
      <Container>
        <form onSubmit={(e) => { handleSubmit(e) }} style={{ width: "100%" }}>
          <Row className="w-100">
            <Col xs={12} sm={12} lg={12} md={12}>
              <span>Plan Name* : </span>
              <input type='text' defaultValue={entryData.name} onChange={handleChange} className='form-control w-100' name='name' id="name" />
              <label className='error'>
                {validationError && validationError.name}
              </label>
            </Col>
            <Col xs={12} sm={12} lg={12} md={12}>
              <span>Plan Description* : </span>
              <CKEditor
                initData={entryData.description}
                config={{
                  toolbar: [
                    ['Cut', 'Copy', 'Paste'],
                    ['Styles', 'Format', 'Font', 'FontSize'],
                    ['Bold', 'Italic', 'Strike'],
                    ['Undo', 'Redo'],
                    ['NumberedList', 'BulletedList', 'Blockquote', 'Outdent', 'Indent'],
                    ['Link', 'Unlink'],
                    ['Table', 'HorizontalRule'],
                    ['Maximize']
                  ]
                }
                }
                onChange={handleEditorChange}
              />
              <label className='error'>
                {validationError && validationError.description}
              </label>
            </Col>
            <Col xs={12} sm={12} lg={6} md={6}>
              <span>Amount($)* : </span>
              <input  type='number' defaultValue={entryData.amount} onChange={handleChange} className='form-control w-100' name='amount' id="amount" />
              <label className='error'>
                {validationError && validationError.amount}
              </label>
            </Col>
            <Col xs={12} sm={12} lg={6} md={6} className="d-none">
              <span>Discount(%)* : </span>
              <input  type='number' defaultValue={entryData.discount} onChange={handleChange} className='form-control w-100' name='discount' id="discount" />
              <label className='error'>
                {validationError && validationError.discount}
              </label>
            </Col>
            {/* <Col xs={12} sm={12} lg={6} md={6}>
              <span>Candidate Limit* : </span>
              <input  type='number' defaultValue={entryData.candidate_limit} onChange={handleChange} className='form-control w-100' name='candidate_limit' id="candidate_limit" />
              <label className='error'>
                {validationError && validationError.candidate_limit}
              </label>
            </Col>
            
            <Col xs={12} sm={12} lg={6} md={6}>
              <span>Active Jobs Limit* : </span>
              <input  type='number' defaultValue={entryData.jobs_limit} onChange={handleChange} className='form-control w-100' name='jobs_limit' id="jobs_limit" />
              <label className='error'>
                {validationError && validationError.jobs_limit}
              </label>
            </Col> */}
            {/* <Col xs={12} sm={12} lg={6} md={6}>
              <span>Select Role* : </span>
              <Select
                options={subscriptionRole}
                name='role'
                id='role'
                placeholder={'Select Role'}
                defaultValue={selectedRole}
                onChange={(event) => setSelectedRole(event?.value || '')}
                menuPosition='fixed'
              />
              <label className='error'>
                {validationError && validationError.role}
              </label>
            </Col> */}
            <Col xs={12} sm={12} lg={6} md={6}>
              <span>Select Status* : </span>
              <Select
                options={subscriptionStatus}
                name='status'
                id='status'
                placeholder={'Select Status'}
                defaultValue={selectedStatus}
                onChange={(event) => setSelectedStatus(event?.value || '')}
                menuPosition='fixed'
              />
              <label className='error'>
                {validationError && validationError.status}
              </label>
            </Col>
          </Row>
          <Row className="w-100 modal-entry-form-row" >
            <Col xs={12} sm={12} lg={12}>
              <Button type='submit' disabled={btnDisabled} className='btn btn-primary btn-radius'>Save</Button>
            </Col>
          </Row>
        </form>
      </Container>
    </>
  )
}

export default SubscriptionPlanAdd