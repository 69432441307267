import React, { useEffect, useState, useRef } from 'react'
import TagList from '../../TagList/TagList'
import { nanoid } from 'nanoid'

import './style/tagInput.scss'
import FilterAutoComplete from './FilterAutoComplete'

const selectType = ['city', 'state', 'company_names', 'schools', 'companyNames']

function TagInput({
    setFilter,
    testAttr,
    initialValues = [],
    candidatePage,
    showTag = true,
}) {
    const [tags, setTags] = useState([...initialValues])
    const [showInput, setShowInput] = useState(false)
    const inputRef = useRef()
    const buttonRef = useRef()

    useEffect(() => {
        if (selectType.includes(testAttr)) {
            setFilter(
                testAttr,
                tags.map((t) => t)
            )
        } else {
            setFilter(
                testAttr,
                tags.map((t) => t.value)
            )
        }

        if (buttonRef.current) {
            buttonRef.current.focus()
        }
    }, [tags])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    })

    useEffect(() => {
        setTags([...initialValues])
    }, [initialValues.length])

    const handleInputKey = (e) => {
        if (e.which === 13 && e.target.value != '') {
            const cloneTagValue = tags.map(({ value }) => value.toLowerCase())
            cloneTagValue.push(e.target.value.toLowerCase())
            const uniqTagsValues = [...new Set(cloneTagValue)]
            const newTags = uniqTagsValues.map((tag) => {
                return { id: nanoid(), value: tag }
            })
            setTags([...newTags])
            setShowInput(false)
        } else if (e.which === 13 && e.target.value === '') {
            setShowInput(false)
        }
    }

    const handleSelectName = (e, name) => {
        if (selectType.includes(name)) {
            setTags(tags.concat({ id: e.value, value: e.label }))
            setShowInput(false)
        }
    }

    const getInput = () => {
        if (selectType.includes(testAttr)) {
            return (
                <FilterAutoComplete
                    getFilterValue={(e) => {
                        handleSelectName(e, testAttr)
                    }}
                    testAttr={testAttr}
                    onBlur={() => {
                        setShowInput(false)
                    }}
                    initialValues={initialValues}
                />
            )
        } else {
            return (
                <div style={{ width: '100%' }}>
                    <input
                        type="text"
                        placeholder="Search..."
                        className="tagTextInput"
                        onKeyPress={handleInputKey}
                        onBlur={() => setShowInput(false)}
                        ref={inputRef}
                    />
                </div>
            )
        }
    }

    return (
        <div>
            {showTag && (
                <TagList
                    tags={tags}
                    removeTagFunc={(id) =>
                        setTags(tags.filter((tag) => tag.id !== id))
                    }
                    maxTagWidth={'100%'}
                    shadows
                />
            )}
            {!showInput && (
                <button
                    ref={buttonRef}
                    onClick={() => {
                        showTag && setShowInput(true)
                    }}
                    style={{ backgroundColor: candidatePage ? '#E6E9FC' : '' }}
                    className={`${
                        candidatePage ? 'circle-btn' : 'circle-btn'
                    } ${showTag ? '' : 'pointer-block'}`}
                >
                    +
                </button>
            )}
            {showInput && getInput()}
        </div>
    )
}



export default TagInput
