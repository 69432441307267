import React, { useState, useEffect } from 'react'
import { CKEditor } from 'ckeditor4-react';
import PropTypes from 'prop-types'

const CustomRichTextarea = ({ handleContentChange, fieldValue }) => {
    const [text, setText] = useState(fieldValue)
    const handleChange = (value) => {
        setText(value.editor.getData())
        handleContentChange(value.editor.getData())
    }

    return (
        <CKEditor
            initData={text}
            config={{
                toolbar: [
                    [ 'Cut', 'Copy', 'Paste'],
                    [ 'Styles', 'Format', 'Font', 'FontSize' ],
                    [ 'Bold', 'Italic', 'Strike' ],
                    [ 'Undo', 'Redo' ],
                    ['NumberedList', 'BulletedList', 'Blockquote', 'Outdent', 'Indent'],
                    ['Link', 'Unlink'],
                    ['Table', 'HorizontalRule'],
                    ['Maximize']
                ]}
            }
            onChange={handleChange}
        />
    )
}

CustomRichTextarea.propTypes = {
    handleContentChange: PropTypes.func.isRequired,
    styles: PropTypes.object.isRequired,
}

export default CustomRichTextarea
