import React, { useEffect, useState } from 'react'
import Image from 'react-bootstrap/Image'
import Select from 'react-select'
import { Row, Col , Spinner} from 'react-bootstrap'

import ImageOne from '../../../../assets/images/on_demand_recruiter/image-one-on-demand.png'
import ImageTwo from '../../../../assets/images/on_demand_recruiter/image-two-on-demand.png'
import ImageThree from '../../../../assets/images/on_demand_recruiter/image-three-on-demand.jpeg'
import CourseraLogo from '../../../../assets/images/on_demand_recruiter/coursera-logo.svg'
import ColorLogo from '../../../../assets/images/on_demand_recruiter/color-logo.svg'
import AdobeLogo from '../../../../assets/images/on_demand_recruiter/adobe-logo.svg'
import RobinhoodLogo from '../../../../assets/images/on_demand_recruiter/robinhood-logo.svg'
import BoxLogo from '../../../../assets/images/on_demand_recruiter/box-logo.svg'
import AdministrationIcon from '../../../../assets/images/on_demand_recruiter/administration.svg'
import DataAnalyticsIcon from '../../../../assets/images/on_demand_recruiter/data-analytics.svg'
import DesignIcon from '../../../../assets/images/on_demand_recruiter/design.svg'
import DevOpsIcon from '../../../../assets/images/on_demand_recruiter/dev-ops.svg'
import FinanceIcon from '../../../../assets/images/on_demand_recruiter/finance.svg'
import HumanResourcesIcon from '../../../../assets/images/on_demand_recruiter/human-resources.svg'
import InformtionTechIcon from '../../../../assets/images/on_demand_recruiter/information-tech.svg'
import MarketingIcon from '../../../../assets/images/on_demand_recruiter/marketing.svg'
import ProductManagementIcon from '../../../../assets/images/on_demand_recruiter/product-management.svg'
import ProjectManagementIcon from '../../../../assets/images/on_demand_recruiter/project-management.svg'
import SalesIcon from '../../../../assets/images/on_demand_recruiter/sales.svg'
import SoftwareEngineeringIcon from '../../../../assets/images/on_demand_recruiter/software-engineering.svg'
import MapIcon from '../../../../assets/images/recruiter_home_icons/icon-map.svg'
import CalendarIcon from '../../../../assets/images/recruiter_home_icons/icon-calendar.svg'

import PeterImage from '../../../../assets/images/on_demand_recruiter/peter-anthony.svg'
import PaulImage from '../../../../assets/images/on_demand_recruiter/paul-campbell.svg'
import LawrenceImage from '../../../../assets/images/on_demand_recruiter/lawrence.jpeg'
import AlexanderImage from '../../../../assets/images/on_demand_recruiter/alexander.png'

import './styles/OnDemandRecruiterPagemodule.scss'
import {
    H1,
    P,
    Button,
    FilterButton,
    TopSection,
    TopListSection,
    Card,
} from './styles/OnDemandRecruiter.styled'
import '../RecruiterHomePage/styles/RecruiterHomePageModule.scss'

const top_recruiter_list2 = [
    {id:1, first_name: "Paul", rate: "$75 Per Hour", location:"Palo Alto, California, United States", name:"Paul Campbell", about:"I have been recruiting in the Bay Area for about 10 years mainly for tech startups and have been very successful at finding great engineers for the startups that I have worked with! In college, I attended UC Berkeley and studied Civil Engineering and continued to play sports mainly through intramurals.  For graduate school, I attended Stanford and studied Engineering and also took several business related courses. These days I am very interested in cycling and every few years I do a triathlon.", link_name: "Paul_Campbell", image:PaulImage, skills:['Recruiting','Cloud Computing','Software Development','SaaS','Start-ups','Product Management']},
    {id:2, first_name: "Peter", rate: "$75 Per Hour", location:"New York", name:"Peter Anthony", about:"I have a fervor for connecting the right people to the right opportunities. Too often people miss out on their dream role, and employers miss out on their ideal candidate; simply due to an overwhelming amount of generic emails, pointless notifications and a lack of intentional connections. I look to bridge that gap every day.", link_name: "Peter_Anthony", image:PeterImage, skills:['Recruiting','Technical Recruiting','Troubleshooting','Business Development','Account Management','Full-life Cycle Recruiting','E-commerce']},
    {id:3, first_name: "Lawrence", rate: "$75 Per Hour", location:"New York City Metropolitan Area", name:"Lawrence Wang", about:"I lead an all star recruiting team supporting multiple industries including fintech, financial services, biotech, SaaS, and more!Have helped numerous people find their next home, let’s find yours!", link_name: "Lawrence_Wang", image:LawrenceImage, skills:['Recruiting','Communication','Leadership','Teamwork','Human Resources (HR)','Technical Recruiting','Management','Social Media Marketing']},
    {id:4, first_name: "Alexander", rate: "$75 Per Hour", location:"San Francisco Bay Area", name:"Alexander Lorimer", about:"I work with world-class startups and tech companies to get them the talent that will help secure their growth. Full-Cycle experience in sourcing, screening, scheduling, reference checks, assisting with the offer process, and improving / streamlining the hiring process.", link_name: "Alexander_Lorimer", image:AlexanderImage , skills:['Recruiting','Leadership','Data Analysis','Laboratory Skills','Python','LabVIEW']}
];
const top_recruiter_list = [
    // {id:1, name:"Paul Campbell", image:PaulImage},
    // {id:2, name:"Peter Anthony", image:PeterImage},
    // {id:3, name:"Paul Campbell", image:PaulImage},
    // {id:4, name:"Peter Anthony", image:PeterImage}
];

const skillList = [
    { value: 'all', label: 'All' },
    { value: 'Critical Thinking', label: 'Critical Thinking' },
    { value: 'Problem Solving', label: 'Problem Solving' },
    { value: 'Creativity', label: 'Creativity' }
];

const rateList = [
    { value: 'all', label: 'All' },
    { value: '30', label: '$30-$50' },
    { value: '50', label: '$50-$75' },
    { value: '75', label: '$75-$100' },
    { value: '100', label: '> $100' }
];

const locations = [
    { value: 'all', label: 'All' },
    { value: 'New York', label: 'New York' },
    { value: 'San Francisco', label: 'San Francisco' },
    { value: 'Las Vegas', label: 'Las Vegas' },
    { value: 'Washington, D.C', label: 'Washington D.C' },
    { value: 'Texas', label: 'Texas' }
];


const businessSizesList = [
    { value: 'all', label: 'All' },
    { value: 'Business with 1-9 Employees', label: 'Business with 1-9 Employees' },
    { value: 'Business with 10-99 Employees', label: 'Business with 10-99 Employees' },
    { value: 'Fortune 500 Company', label: 'Fortune 500 Company' }
];

const industriesList = [
    { value: 'all', label: 'All' },
    { value: 'Administartion', label: 'Administartion' },
    { value: 'Information Technology and Services', label: 'Information Technology and Services' },
    { value: 'Hospital & Health Care', label: 'Hospital & Health Care' },
    { value: 'Financial Services', label: 'Financial Services' },
    { value: 'Accounting', label: 'Accounting' },
    { value: 'Computer Software', label: 'Computer Software' },
    { value: 'Government Administration', label: 'Government Administration' },
    { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },
    { value: 'Human Resources', label: 'Human Resources' },
    { value: 'Management Consulting', label: 'Management Consulting' },
    { value: 'Logistics & Supply Chain', label: 'Logistics & Supply Chain' },
    { value: 'Architecture & Planning', label: 'Architecture & Planning' },
    { value: 'Facilities Services', label: 'Facilities Services' },
    { value: 'Professional Training & Coaching', label: 'Professional Training & Coaching' },
    { value: 'Graphic Design', label: 'Graphic Design' },
    { value: 'Broadcast Media', label: 'Broadcast Media' },
    { value: 'Business Supplies & Equipment', label: 'Business Supplies & Equipment' },
    { value: 'Computer Hardware', label: 'Computer Hardware' },
    { value: 'Computer & Network Security', label: 'Computer & Network Security' },
    { value: 'Investment Management', label: 'Investment Management' },
    { value: 'Information Services', label: 'Information Services' },
    { value: 'Product Management', label: 'Product Management' },
    { value: 'Project Management', label: 'Project Management' },
    { value: 'Data Analytics', label: 'Data Analytics' },
    { value: 'Dev-Ops', label: 'Dev-Ops' },
    { value: 'Events Services', label: 'Events Services' },
    { value: 'Executive Office', label: 'Executive Office' },
    { value: 'Packaging and Containers', label: 'Packaging and Containers' },
    { value: 'Market Research', label: 'Market Research' }

];

const offers = ["Contingency", "Full-time", "Part-time", "Hybrid"];
const skills = ["Critical Thinking", "Problem Solving", "Creativity", "Leadership"];
const businessSizes = ["Business with 1-9 Employees", "Business with 10-99 Employees ", "Business with 100-999 Employees", "Fortune 500 Company", "Business with 1000+ Employees"];
const industries = ["Product Management", "Administartion", "Data Analytics", "Project Management", "Software Engineering"];

const DetailCard = ({ title, value }) => {
    return (
        <div className="d-flex flex-column recruiterDetails">

            <span className="articles">{title}</span>
            <span className="number1">{value}</span>
            
        </div>
    )
}

const RecruiterBadges = ({title, badgesList}) => {
    return (
        <>
        <span className="font-weight-bold">{title}:  </span>
        {badgesList.map((data) => {
            return ( <span className="badge badge-pill badge-primary">{data}</span>
            )
        })}
        </>
    )
}

const IndustryCard = ({ image, industry }) => {
    return (
        <Card>
            <Image src={image} />
            <P
                size={13}
                height={18}
                marginTop={12}
                color="#606BE4"
                weight={800}
            >
                {industry}
            </P>
        </Card>
    )
}

const OnDemandRecruiterPage = ({currentUser,states}) => {
    
    const [locations, setLocations] = useState('')
    const [selectedRates, setRatesFilter] = useState('')
    const [selectedLocation, setLocationsFilter] = useState('')
    const [selectedIndustries, setIndustriesFilter] = useState('')
    const [selectedBusinessSizes,setBusinessSizesFilter] = useState('')
    
    useEffect(() => {
        setLocations(states)
    }, []);
    return (
        <>
            <TopSection>
                <Col sm={12} xl={7}>
                    <H1>Hire Top  3% Recruiters</H1>
                    <P>
                        From Stanford, UC Berkeley, UCLA, UPenn, Columbia, or
                        Harvard
                    </P>
                    <div className="hire-btns" style={{ marginTop: '40px' }}>
                        <Button
                            onClick={() =>
                                (window.location.href = '/welcome/top_recruiter')
                            }
                        >
                            Hire a top recruiter
                        </Button>
                        <Button
                            onClick={() =>
                                (window.location.href = '/welcome/become_recruiter')
                            }
                            marginLeft={15}
                        >
                            Become a top recruiter
                        </Button>
                    </div>
                    <div
                        className="d-lg-flex flex-wrap hire-logo"
                        style={{ marginTop: '53px' }}
                    >
                        <Image
                            src={CourseraLogo}
                            style={{ marginRight: '76px' }}
                        />
                        <Image
                            src={ColorLogo}
                            style={{ marginRight: '76px' }}
                        />
                        <Image
                            src={AdobeLogo}
                            style={{ marginRight: '76px' }}
                        />
                        <Image
                            src={RobinhoodLogo}
                            style={{ marginRight: '76px' }}
                        />
                        <Image src={BoxLogo} />
                    </div>
                </Col>
                <Col sm={5} className="d-none d-xl-block">
                    <Image
                        src={ImageThree}
                        style={{
                            position: 'absolute',
                            //right: '480px',
                        }}
                    />
                    <Image
                        src={ImageOne}
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            right: '30%',
                            zIndex: '2',
                        }}
                    />
                    <Image
                        src={ImageTwo}
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                        }}
                    />
                </Col>
            </TopSection>

            <TopSection
                style={{ paddingTop: '60px' }}
                className="d-flex flex-column"
            >
                <P
                    size={24}
                    height={33}
                    marginTop={0}
                    color="#424A73"
                    className="align-self-center"
                >
                    Work with our top recruiters
                </P>
                <Row
                    className="d-flex justify-content-around"
                    style={{ marginTop: '30px' }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <Image src={PaulImage} style={{borderRadius: '25px', width:'170px'}}  />
                        <P size={12} height={16} marginTop={7} color="#272E50">
                            Paul Campbell
                        </P>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <Image src={PeterImage} style={{borderRadius: '25px', width:'170px'}} />
                        <P size={12} height={16} marginTop={7} color="#272E50">
                            Peter Anthony
                        </P>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <Image src={LawrenceImage} style={{borderRadius: '25px', width:'170px'}} />
                        <P size={12} height={16} marginTop={7} color="#272E50">
                            Lawrence Wang
                        </P>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <Image src={AlexanderImage} style={{borderRadius: '25px', width:'170px'}} />
                        <P size={12} height={16} marginTop={7} color="#272E50">
                            Alexander Lorimer
                        </P>
                    </div>
                    {/* <div className="d-flex flex-column align-items-center">
                        <Image src={PeterImage} style={{borderRadius: '25px'}} />
                        <P size={12} height={16} marginTop={7} color="#272E50">
                            Peter Anthony
                        </P>
                    </div> */}
                </Row>
            </TopSection>

            <TopSection
                style={{ paddingTop: '42px' }}
                className="d-flex flex-column"
            >
                <P
                    size={24}
                    height={33}
                    marginTop={0}
                    color="#424A73"
                    className="align-self-center"
                >
                    Find a top recruiter for any industry
                </P>
                <Row className="industry-icon"
                    style={{
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <IndustryCard
                        image={AdministrationIcon}
                        industry="Administartion"
                    />
                    <IndustryCard
                        image={DataAnalyticsIcon}
                        industry="Data Analytics"
                    />
                    <IndustryCard image={DesignIcon} industry="Design" />
                    <IndustryCard image={DevOpsIcon} industry="Dev-Ops" />
                    <IndustryCard image={FinanceIcon} industry="Finance" />
                    <IndustryCard
                        image={HumanResourcesIcon}
                        industry="Human Resources"
                    />
                </Row>
                <Row className="industry-icon"
                    style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <IndustryCard
                        image={InformtionTechIcon}
                        industry="Information Tech"
                    />
                    <IndustryCard image={MarketingIcon} industry="Marketing" />
                    <IndustryCard
                        image={ProductManagementIcon}
                        industry="Product Management"
                    />
                    <IndustryCard
                        image={ProjectManagementIcon}
                        industry="Project Management"
                    />
                    <IndustryCard image={SalesIcon} industry="Sales" />
                    <IndustryCard
                        image={SoftwareEngineeringIcon}
                        industry="Software Engineering"
                    />
                </Row>
            </TopSection>
            <TopSection
                style={{ paddingTop: '42px',marginBottom: '30px' }}
                className="d-flex flex-column"
            >
                <P
                    size={24}
                    height={33}
                    marginTop={0}
                    color="#424A73"
                    className="align-self-center"
                >
                    Top Recruiters
                </P>
            </TopSection>
            <TopListSection>
                <Row className="w-100 filter_card">
                    <Col lg="10" md="10" sm="12" xs="12" className='p-0'>
                        <div style={{display: 'flex'}}>
                            <div style={{marginLeft:'10px'}}>
                                <label>
                                    Rates
                                </label>
                                <Select
                                    options={rateList}
                                    onChange={(event)=>setRatesFilter(event.value)}
                                    name="rates"
                                    className="colourStyles"
                                />
                            </div>
                            <div>
                                <label>
                                    Locations
                                </label>
                                <Select
                                    options={locations}
                                    onChange={(event)=>setLocationsFilter(event.value)}
                                    name="locations"
                                    className="colourStyles"
                                />
                            </div>
                            <div>
                                <label>
                                    Industries
                                </label>
                                <Select
                                    options={industriesList}
                                    onChange={(event)=>setIndustriesFilter(event.value)}
                                    name="industries"
                                    className="colourStyles"
                                />
                            </div>
                            <div>
                                <label>
                                    Business Sizes
                                </label>
                                <Select
                                    options={businessSizesList}
                                    onChange={(event)=>setBusinessSizesFilter(event.value)}
                                    name="businessSizes"
                                    className="colourStyles"
                                />
                            </div>
                            <div>
                                <FilterButton
                                    onClick={() =>
                                        (window.location.href = '/welcome/top_recruiter?rates='+selectedRates+"&&location="+selectedLocation+"&&industries="+selectedIndustries+"&&businessSizes="+selectedBusinessSizes)
                                    }
                                >
                                    Search
                                </FilterButton>
                            </div>
                        </div>
                    </Col>
                    <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-center'>
                        {/* {totalUsers > 0 &&
                            <DisplayPagination>Displaying { ((startRecord == 0)?1:startRecord) + '-' +currentUserCount} of { `${totalUsers} Recruiters`}</DisplayPagination>
                        } */}
                    </Col>
                </Row>
            </TopListSection>
            {top_recruiter_list.map((data) => {
                return (    
                    <TopListSection key={data.id}>
                        <div className="card p-3">
                            <div className="d-flex">
                                <div className="image">
                                    <Image src={data.image} />
                                </div>
                                <div className="ml-4 w-100">
                                    <div className="p-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                        <h4 className="mb-0 mt-0">{data.name}</h4>
                                        <Button
                                            onClick={() =>
                                                (window.location.href = '/recruiter_contact/'+data.id+'/'+data.name?.split(' ')?.join('_'))
                                            }
                                        >
                                            Contact
                                        </Button>
                                    </div>
                                    <div className="p-2 bg-primary d-flex  rounded text-white stats">
                                        <DetailCard title="Rate" value="$75 Per Hour" />
                                        <DetailCard title="Location" value="United States" />
                                    </div>
                                    <div className="p-2 bg-primary d-flex recruiterBadges rounded text-white stats">
                                        <RecruiterBadges title="Offer Consider" badgesList={offers} ></RecruiterBadges>
                                    </div>
                                </div>
                            </div>
                            <div className="recruiterBadges">
                                <RecruiterBadges title="Skills" badgesList={skills} ></RecruiterBadges>
                            </div>
                            <div className="recruiterBadges">
                                <RecruiterBadges title="Industries" badgesList={industries} ></RecruiterBadges>
                            </div>
                            <div className="recruiterBadges">
                                <RecruiterBadges title="Business Size Experience" badgesList={businessSizes} ></RecruiterBadges>
                            </div>
                        </div>
                    </TopListSection>
                )}
            )}
            <section className='talent-section'>
                <article className="talent-list">
                    {top_recruiter_list2.map((data) => {
                        return (
                            <div key={data.id} className="talent-listing">
                                <div className="talent-card talent-card-border" style={{display: 'flex'}}>
                                    <div className="talent-card-left">
                                        <a href={'/home_recruiter_profile/'+data.id+'/'+data.link_name} itemProp="url">
                                            <div className="talent-profile-img"><span>View Full Profile</span></div>
                                            <img src={data.image} className="talent-user-img" />
                                        </a>
                                        <a href={'/home_recruiter_profile/'+data.id+'/'+data.link_name} className="talent-button talent-button-ui">View {data.first_name}</a>
                                    </div>
                                    <div className="talent-card-right">
                                        <div>
                                            <h3 itemProp="name"><a href={'/home_recruiter_profile/'+data.id+'/'+data.link_name} className="talent-name ">{data.name}</a></h3>
                                            <div className="talent-details">
                                                <span className=""><img src={MapIcon} style={{height: '20px',width:'20px',marginRight: '10px'}}/> {data.location}</span>
                                                <span className=""><img src={CalendarIcon} style={{height: '18px',width:'18px',marginRight: '10px'}}/>{data.rate}</span>
                                            </div>
                                            <p className="talent-desc" itemProp="description">{data.about}</p>
                                            <a href={'/home_recruiter_profile/'+data.id+'/'+data.link_name} data-testid="read-more-link" className="talent_showmore">Show More</a>
                                        </div>
                                        <div className="talent-skill">
                                            <div className="talent-skill-list">
                                                {data.skills.map((skill) => {
                                                    return (
                                                        <a href="#" key={skill} className="talent-skill-tag talent-tag-list">{skill}</a>
                                                    )}
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    )}
                </article>
            </section>
            <TopSection
                style={{ paddingTop: '42px' }}
            ></TopSection>
        </>
    )
}

export default OnDemandRecruiterPage
