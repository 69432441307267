import React, { useEffect, useState } from 'react'
import Image from 'react-bootstrap/Image'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import ImageOne from '../../../../assets/images/about_us_page_assets/about-us.png'
import '../OnDemandRecruiterPage/styles/OnDemandRecruiterPagemodule.scss'
import Paginator from '../../common/Paginator/Paginator'
import {firstCharacterCapital} from '../../../utils/index'
import Util from '../../../utils/util'
import Select from 'react-select'
import { Row, Col , Spinner} from 'react-bootstrap'
import PeterImage from '../../../../assets/images/on_demand_recruiter/peter-anthony.svg'
import PaulImage from '../../../../assets/images/on_demand_recruiter/paul-campbell.svg'
import LawrenceImage from '../../../../assets/images/on_demand_recruiter/lawrence.jpeg'
import AlexanderImage from '../../../../assets/images/on_demand_recruiter/alexander.png'
import MapIcon from '../../../../assets/images/recruiter_home_icons/icon-map.svg'
import CalendarIcon from '../../../../assets/images/recruiter_home_icons/icon-calendar.svg'

import {
    Wrapper,
    AboutUsSection,
    AboutUsText,
    AlertSection,
    TopListSection,
    Button,
    FilterButton,
    P,
    TeamSection,
    DisplayPagination,
} from './styles/TopRecuirterPage.styled'
import '../RecruiterHomePage/styles/RecruiterHomePageModule.scss'

// const top_recruiter_list = [
//     {id:1, first_name: "Paul", last_name: "Campbell", rate: "$75 Per Hour", profile_url: '/home_recruiter_profile/1/Paul_Campbell', location:"Palo Alto, California, United States", name:"Paul Campbell", about:"I have been recruiting in the Bay Area for about 10 years mainly for tech startups and have been very successful at finding great engineers for the startups that I have worked with! In college, I attended UC Berkeley and studied Civil Engineering and continued to play sports mainly through intramurals.  For graduate school, I attended Stanford and studied Engineering and also took several business related courses. These days I am very interested in cycling and every few years I do a triathlon.", link_name: "Paul_Campbell", avatar_url:PaulImage, skills:['Recruiting','Cloud Computing','Software Development','SaaS','Start-ups','Product Management']},
//     {id:2, first_name: "Peter", last_name: "Anthony", rate: "$75 Per Hour", profile_url: '/home_recruiter_profile/2/Peter_Anthony', location:"New York", name:"Peter Anthony", about:"I have a fervor for connecting the right people to the right opportunities. Too often people miss out on their dream role, and employers miss out on their ideal candidate; simply due to an overwhelming amount of generic emails, pointless notifications and a lack of intentional connections. I look to bridge that gap every day.", link_name: "Peter_Anthony", avatar_url:PeterImage, skills:['Recruiting','Technical Recruiting','Troubleshooting','Business Development','Account Management','Full-life Cycle Recruiting','E-commerce']},
//     {id:3, first_name: "Lawrence", last_name: "Wang", rate: "$75 Per Hour", profile_url: '/home_recruiter_profile/3/Lawrence_Wang', location:"New York City Metropolitan Area", name:"Lawrence Wang", about:"I lead an all star recruiting team supporting multiple industries including fintech, financial services, biotech, SaaS, and more!Have helped numerous people find their next home, let’s find yours!", link_name: "Lawrence_Wang", avatar_url:LawrenceImage, skills:['Recruiting','Communication','Leadership','Teamwork','Human Resources (HR)','Technical Recruiting','Management','Social Media Marketing']},
//     {id:4, first_name: "Alexander", last_name: "Lorimer", rate: "$75 Per Hour", profile_url: '/home_recruiter_profile/4/Alexander_Lorimer', location:"San Francisco Bay Area", name:"Alexander Lorimer", about:"I work with world-class startups and tech companies to get them the talent that will help secure their growth. Full-Cycle experience in sourcing, screening, scheduling, reference checks, assisting with the offer process, and improving / streamlining the hiring process.", link_name: "Alexander_Lorimer", avatar_url:AlexanderImage , skills:['Recruiting','Leadership','Data Analysis','Laboratory Skills','Python','LabVIEW']}
// ];
const offers = [
    "Contingency", "Full-time", "Part-time", "Hybrid"
];

const rates = [
    { value: 'all', label: 'All' },
    { value: '30', label: '$30-$50' },
    { value: '50', label: '$50-$75' },
    { value: '75', label: '$75-$100' },
    { value: '100', label: '> $100' }
];

const default_locations = [
    { value: 'all', label: 'All' },
    { value: 'New York', label: 'New York' },
    { value: 'San Francisco', label: 'San Francisco' },
    { value: 'Las Vegas', label: 'Las Vegas' },
    { value: 'Washington, D.C', label: 'Washington D.C' }
];

const skills = [
    { value: 'all', label: 'All' },
    { value: 'Product Management', label: 'Product Management' },
    { value: 'Critical Thinking', label: 'Critical Thinking' },
    { value: 'Problem Solving', label: 'Problem Solving' },
    { value: 'Creativity', label: 'Creativity' }
];

const businessSizes = [
    { value: 'all', label: 'All' },
    { value: 'Business with 1-9 Employees', label: 'Business with 1-9 Employees' },
    { value: 'Business with 10-99 Employees', label: 'Business with 10-99 Employees' },
    { value: 'Fortune 500 Company', label: 'Fortune 500 Company' }
];

const industries = [
    { value: 'all', label: 'All' },
    { value: 'Administartion', label: 'Administartion' },
    { value: 'Information Technology and Services', label: 'Information Technology and Services' },
    { value: 'Hospital & Health Care', label: 'Hospital & Health Care' },
    { value: 'Financial Services', label: 'Financial Services' },
    { value: 'Accounting', label: 'Accounting' },
    { value: 'Computer Software', label: 'Computer Software' },
    { value: 'Government Administration', label: 'Government Administration' },
    { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },
    { value: 'Human Resources', label: 'Human Resources' },
    { value: 'Management Consulting', label: 'Management Consulting' },
    { value: 'Logistics & Supply Chain', label: 'Logistics & Supply Chain' },
    { value: 'Architecture & Planning', label: 'Architecture & Planning' },
    { value: 'Facilities Services', label: 'Facilities Services' },
    { value: 'Professional Training & Coaching', label: 'Professional Training & Coaching' },
    { value: 'Graphic Design', label: 'Graphic Design' },
    { value: 'Broadcast Media', label: 'Broadcast Media' },
    { value: 'Business Supplies & Equipment', label: 'Business Supplies & Equipment' },
    { value: 'Computer Hardware', label: 'Computer Hardware' },
    { value: 'Computer & Network Security', label: 'Computer & Network Security' },
    { value: 'Investment Management', label: 'Investment Management' },
    { value: 'Information Services', label: 'Information Services' },
    { value: 'Product Management', label: 'Product Management' },
    { value: 'Project Management', label: 'Project Management' },
    { value: 'Data Analytics', label: 'Data Analytics' },
    { value: 'Dev-Ops', label: 'Dev-Ops' },
    { value: 'Events Services', label: 'Events Services' },
    { value: 'Executive Office', label: 'Executive Office' },
    { value: 'Packaging and Containers', label: 'Packaging and Containers' },
    { value: 'Market Research', label: 'Market Research' }
];

const yearsOfExperiencesList = [
    { value: 'all', label: 'All' },
    { value: '0-1', label: '<1 Year' },
    { value: '1-2', label: '1-2 Year' },
    { value: '2-5', label: '2-5 Year' },
    { value: '5-10', label: '5-10 Year' },
    { value: '10-20', label: '10+ Year' },
];

const DetailCard = ({ title, value }) => {
    return (
        <div className="d-flex flex-column recruiterDetails">

            <span className="articles">{title}</span>
            <span className="number1">{value}</span>
            
        </div>
    )
}

const RecruiterBadges = ({title, badgesList}) => {
    return (
        <>
        <span className="font-weight-bold">{title}:  </span>
        {badgesList.map((data) => {
            return ( <span className="badge badge-pill badge-primary">{data}</span>
            )
        })}
        </>
    )
}

const TopRecruiterList = ({top_recruiter_list}) => {
    
    return (
        <>
            <section className='talent-section'>
                <article className="talent-list">
                    {top_recruiter_list.map((data) => {
                        return (
                            <div key={data.id} className="talent-listing">
                                <div className="talent-card talent-card-border" style={{display: 'flex'}}>
                                    <div className="talent-card-left">
                                        <a href={data.profile_url} itemProp="url">
                                            <div className="talent-profile-img"><span>View Full Profile</span></div>
                                            {data.avatar_url ? (
                                                <img src={data.avatar_url} className="talent-user-img" />
                                            ) : (
                                                <div className="candidateAvtarImage">{ (data.first_name) ? (data?.first_name && Util.getFirstCharater(data?.first_name)) + (data?.last_name && Util.getFirstCharater(data?.last_name) ) : (data?.email && Util.getFirstCharater(data?.email) ) }</div>
                                            )} 
                                        </a>
                                        <a href={data.profile_url} className="talent-button talent-button-ui">View {data.first_name}</a>
                                    </div>
                                    <div className="talent-card-right">
                                        <div>
                                            <h3 itemProp="name"><a href={data.profile_url} className="talent-name ">{data.name}</a></h3>
                                            <div className="talent-details">
                                                <span className=""><img src={MapIcon} style={{height: '20px',width:'20px',marginRight: '10px'}}/> {data.location}</span>
                                                <span className=""><img src={CalendarIcon} style={{height: '18px',width:'18px',marginRight: '10px'}}/>{data.rate}</span>
                                            </div>
                                            <p className="talent-desc" itemProp="description">{data.about}</p>
                                            <a href={data.profile_url} data-testid="read-more-link" className="talent_showmore">Show More</a>
                                        </div>
                                        <div className="talent-skill">
                                            <div className="talent-skill-list">
                                                {data.skills.map((skill) => {
                                                    return (
                                                        <a href="#" key={skill} className="talent-skill-tag talent-tag-list">{skill}</a>
                                                    )}
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    )}
                </article>
            </section>
        </>
    )
}

const TopRecruiterList2 = ({top_recruiter_list}) => {
    
    return (
        <>
            {top_recruiter_list.map((data) => {
                return (    
                    <TopListSection key={data.id}>
                        <div className="card p-3">
                            <div className="d-flex">
                                <div className="image">
                                    {data.avatar_url ? (
                                        <Image src={data.avatar_url} />
                                    ) : (
                                        <div className="candidateAvtarImage">{ (data.first_name) ? (data?.first_name && Util.getFirstCharater(data?.first_name)) + (data?.last_name && Util.getFirstCharater(data?.last_name) ) : (data?.email && Util.getFirstCharater(data?.email) ) }</div>
                                    )}    

                                </div>
                                <div className="ml-4 w-100">
                                    <div className="p-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                        <h4 className="mb-0 mt-0">{data.name}</h4>
                                        <Button
                                            onClick={() =>
                                                (window.location.href = '/recruiter_contact/'+data.id+'/'+data.name?.split(' ')?.join('_'))
                                            }
                                        >
                                            Contact
                                        </Button>
                                    </div>
                                    <div className="p-2 bg-primary d-flex  rounded text-white stats">
                                        <DetailCard title="Rate" value={data.rate} />
                                        <DetailCard title="Location" value={data.location} />
                                    </div>
                                    <div className="p-2 bg-primary d-flex recruiterBadges rounded text-white stats">
                                        <RecruiterBadges title="Offer Consider" badgesList={data.offers} ></RecruiterBadges>
                                    </div>
                                </div>
                            </div>
                            <div className="recruiterBadges">
                                <RecruiterBadges title="Skills" badgesList={data.skills} ></RecruiterBadges>
                            </div>
                            <div className="recruiterBadges">
                                <RecruiterBadges title="Industries" badgesList={data.industries} ></RecruiterBadges>
                            </div>
                            <div className="recruiterBadges">
                                <RecruiterBadges title="Business Size Experience" badgesList={data.businessSizes} ></RecruiterBadges>
                            </div>
                        </div>
                    </TopListSection>
                )}
            )}
        </>
    )
}

const TopRecruiterPage = ({currentUser,states,all_company,all_univercity}) => {
    const [isAdminConfirmed, setAdminConfirmed] = useState(currentUser.admin_confirmed)

    var active_page = window.sessionStorage.getItem('user_activePage')
    var query = window.sessionStorage.getItem('user_query')
    const queryParams = new URLSearchParams(window.location.search)
    const defaultSkillQury = queryParams.get("skills")
    const defaultIndustryQury = queryParams.get("industries")
    const defaultBusinessQury = queryParams.get("businessSizes")
    const defaultRateQury = queryParams.get("rates")
    const defaultLocationQury = queryParams.get("location")
    const defaultCompanyQury = queryParams.get("company")
    const defaultUnivercityQury = queryParams.get("university")
    const defaultExperienceQury = queryParams.get("experience")
    const [activePage, setActivePage] = useState(parseInt(active_page) || 0)
    const [loading, setLoading] = useState(true)
    const [currentUserCount, setCurrentUserCount] = useState(0)
    const [pageCountUsers, setPageCountUsers] = useState(0)
    const [totalUsers, setTotalusers] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [users, setUsers] = useState([])
    const [locations, setLocations] = useState(states)
    const [allCompany, setCompanys] = useState(all_company)
    const [allUnivercity, setUnivercities] = useState(all_univercity)

    // Filter set up
    const [selectedRates, setRatesFilter] = useState((defaultRateQury)? defaultRateQury : '')
    const [selectedLocation, setLocationsFilter] = useState((defaultLocationQury)? defaultLocationQury : '')
    const [selectedSkills, setSkillsFilter] = useState((defaultSkillQury)? defaultSkillQury : '')
    const [selectedIndustries, setIndustriesFilter] = useState((defaultIndustryQury)? defaultIndustryQury : '')
    const [selectedBusinessSizes,setBusinessSizesFilter] = useState((defaultBusinessQury)? defaultBusinessQury : '')
    const [defaultRate,setDefaultRate] = useState((defaultRateQury)? rates.filter((option) => option.value == defaultRateQury) :{value: 'all', label: 'All'})
    const [defaultLocation,setDefaultLocation] = useState((defaultLocationQury)? locations?.filter((option) => option.value == defaultLocationQury) :{value: 'all', label: 'All'})
    const [defaultSkill,setDefaultSkill] = useState((defaultSkillQury)? skills.filter((option) => option.value == defaultSkillQury) :{value: 'all', label: 'All'})
    const [defaultBusiness,setDefaultBusiness] = useState((defaultBusinessQury)? businessSizes.filter((option) => option.value == defaultBusinessQury) :{value: 'all', label: 'All'})
    const [defaultIndustry,setDefaultIndustry] = useState((defaultIndustryQury)? industries.filter((option) => option.value == defaultIndustryQury) :{value: 'all', label: 'All'})

    //New Filter
    const [selectedCompany, setCompanyFilter] = useState((defaultCompanyQury)? defaultCompanyQury : '')
    const [selectedUnivercity, setUnivercityFilter] = useState((defaultUnivercityQury)? defaultUnivercityQury : '')
    const [selectedExperience, setExperiencedFilter] = useState((defaultExperienceQury)? defaultExperienceQury : '')
    const [defaultCompany,setDefaultCompany] = useState((defaultCompanyQury)? allCompany.filter((option) => option.value == defaultCompanyQury) :{value: 'all', label: 'All'})
    const [defaultUnivercity,setDefaultUnivercity] = useState((defaultUnivercityQury)? allUnivercity.filter((option) => option.value == defaultUnivercityQury) :{value: 'all', label: 'All'})
    const [defaultExperience,setDefaultExperience] = useState((defaultExperienceQury)? yearsOfExperiencesList.filter((option) => option.value == defaultExperienceQury) :{value: 'all', label: 'All'})

    useEffect(() => {
        fetchData();
    }, [activePage,selectedLocation,selectedRates,selectedSkills,selectedIndustries,selectedBusinessSizes,selectedCompany,selectedUnivercity,selectedExperience]);

    const fetchData = async () => {
        setLoading(true)
        window.sessionStorage.setItem('user_activePage',activePage)
        const url = '/top_recruiter_list.json'
        const response = await makeRequest(
            `${url}?page=${activePage + 1}&rates=${selectedRates}&location=${selectedLocation}&industries=${selectedIndustries}&businessSizes=${selectedBusinessSizes}&company=${selectedCompany}&university=${selectedUnivercity}&experience=${selectedExperience}`,
            'get',
            {}
        )

        const users = response.data.users.map((user) => ({
            id: user.id,
            first_name : user.first_name,
            last_name : user.last_name,
            name: user.first_name ? firstCharacterCapital(user.first_name) + ' ' + firstCharacterCapital(user.last_name) : " ",
            location: user.location ? user.location?.replace('(usa)','(US)') : "NA",
            email : user.email,
            avatar_url : user?.image_url ? user?.image_url : user.ct_profile_pic,
            role: firstCharacterCapital(user.role),
            about : user?.cr_recruiter_detail?.about ? user?.cr_recruiter_detail?.about : "" ,
            rate : user?.cr_recruiter_detail?.rate ? "$"+user?.cr_recruiter_detail?.rate+" Per hour" : 'NA',
            skills: user?.cr_recruiter_detail?.skills ? user.cr_recruiter_detail.skills.split(",") : [] ,
            profile_url: '/recruiter_profile/'+user.id+'/'+user.first_name+'_'+user.last_name 
        }))

        // const allUsers = top_recruiter_list.concat(users);
        setUsers(users);
        //Set Filter
        setDefaultLocation((selectedLocation)? locations?.filter((option) => option.value == selectedLocation) :{value: 'all', label: 'All'})
        setDefaultRate((selectedRates)? rates.filter((option) => option.value == selectedRates) :{value: 'all', label: 'All'})
        setDefaultSkill((selectedSkills)? skills.filter((option) => option.value == selectedSkills) :{value: 'all', label: 'All'})
        setDefaultBusiness((selectedBusinessSizes)? businessSizes.filter((option) => option.value == selectedBusinessSizes) :{value: 'all', label: 'All'})
        setDefaultIndustry((selectedIndustries)? industries.filter((option) => option.value == selectedIndustries) :{value: 'all', label: 'All'})

        setDefaultCompany((selectedCompany)? allCompany.filter((option) => option.value == selectedCompany) :{value: 'all', label: 'All'})
        setDefaultUnivercity((selectedUnivercity)? allUnivercity.filter((option) => option.value == selectedUnivercity) :{value: 'all', label: 'All'})
        setDefaultExperience((selectedExperience)? yearsOfExperiencesList.filter((option) => option.value == selectedExperience) :{value: 'all', label: 'All'})
        
        setPageCountUsers(response.data.total_pages_users)
        setTotalusers(response.data.total_users)
        setCurrentUserCount(response.data.current_users_counts)
        setStartRecord(activePage * response.data.per_page)
        setLoading(false)
      //  setSelected((selectedSkills)? skills.filter((option) => option.value == selectedSkills) :{value: 'all', label: 'All'})
    }
    
    return (
        <Wrapper>
            {/* <AboutUsSection className="row">
                <div className="col-md-6">
                    <AboutUsText>
                        <P>
                            From problem solving to innovational thinking, our team
                            strives for the best.
                        </P>
                    </AboutUsText>
                </div>
                <div className="col-md-6">
                    <Image className="img-fluid w-100" src={ImageOne} />
                </div>
            </AboutUsSection> */}
            <TeamSection>
                <div className="d-flex flex-column align-items-center">
                    <P size="40px" height="40px">
                        Top Recruiters
                    </P>
                </div>
            </TeamSection>
        
            {!isAdminConfirmed ? (
                <>
                <AlertSection>
                    <div className="alert alert-danger" role="alert">
                        Your account is awaiting for administrator approval.
                    </div>
                </AlertSection>
                </>
            ) : (
                <>
                {loading ? (
                    <TopListSection>
                    <tr>
                        <td colSpan="10">
                        <P className="text-center" size="14px" height="19px" color="#3B3847">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </P>
                        </td>
                    </tr>
                  </TopListSection>
                ) : (
                    <>
                    <TopListSection>
                        <Row className="w-100 filter_card">
                            <Col lg="10" md="10" sm="12" xs="12" className='p-0'>
                                <div style={{display: 'flex'}}>
                                    {/* <div style={{marginLeft:'10px'}}>
                                        <label>
                                            Skills
                                        </label>
                                        <Select
                                            defaultValue={defaultSkill}
                                            options={skills}
                                            onChange={(event)=>setSkillsFilter(event.value)}
                                            name="skills"
                                            className="colourStyles"
                                        />
                                    </div> */}
                                    {/* <div style={{marginLeft:'10px'}}>
                                        <label>
                                            Rates
                                        </label>
                                        <Select
                                            defaultValue={defaultRate}
                                            options={rates}
                                            onChange={(event)=>{setRatesFilter(event.value); setActivePage(0);}}
                                            name="rates"
                                            className="colourStyles"
                                        />
                                    </div> */}
                                    <div>
                                        <label>
                                            Locations
                                        </label>
                                        <Select
                                            defaultValue={defaultLocation}
                                            options={locations}
                                            onChange={(event)=>{setLocationsFilter(event.value); setActivePage(0);}}
                                            name="locations"
                                            className="colourStyles"
                                        />
                                    </div>
                                    <div>
                                        <label>
                                            Industries
                                        </label>
                                        <Select
                                            defaultValue={defaultIndustry}
                                            options={industries}
                                            onChange={(event)=> {setIndustriesFilter(event.value); setActivePage(0);}}
                                            name="industries"
                                            className="colourStyles"
                                        />
                                    </div>
                                    <div>
                                        <label>
                                            Business Sizes
                                        </label>
                                        <Select
                                            defaultValue={defaultBusiness}
                                            options={businessSizes}
                                            onChange={(event)=> {setBusinessSizesFilter(event.value); setActivePage(0);}}
                                            name="businessSizes"
                                            className="colourStyles"
                                        />
                                    </div>
                                    <div>
                                        <label>
                                            University
                                        </label>
                                        <Select
                                            defaultValue={defaultUnivercity}
                                            options={allUnivercity}
                                            onChange={(event)=>setUnivercityFilter(event.value)}
                                            name="allUnivercity"
                                            className="colourStyles"
                                        />
                                    </div>
                                    <div>
                                        <label>
                                            Company
                                        </label>
                                        <Select
                                            defaultValue={defaultCompany}
                                            options={allCompany}
                                            onChange={(event)=>setCompanyFilter(event.value)}
                                            name="allCompany"
                                            className="colourStyles"
                                        />
                                    </div>
                                    <div>
                                        <label>
                                            Years Of Experience
                                        </label>
                                        <Select
                                            defaultValue={defaultExperience}
                                            options={yearsOfExperiencesList}
                                            onChange={(event)=>setExperiencedFilter(event.value)}
                                            name="allCompany"
                                            className="colourStyles"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg="2" md="2" sm="12" xs="12" className='d-flex align-items-center'>
                                {totalUsers > 0 &&
                                    <DisplayPagination>Displaying { ((startRecord == 0)?1:startRecord) + '-' +currentUserCount} of { `${totalUsers} Recruiters`}</DisplayPagination>
                                }
                            </Col>
                        </Row>
                    </TopListSection>
                    {users.length != 0 ? (
                    <TopRecruiterList top_recruiter_list={users} ></TopRecruiterList>
                    ) : (
                        <TopListSection>
                            <P size="20px" height="20px">
                                No Records Founds! Please try another filter.
                            </P>
                        </TopListSection>
                        
                    )}
                    <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: 'auto' }}
                    >
                        <Paginator
                            activePage={activePage}
                            setActivePage={setActivePage}
                            pageCount={
                                pageCountUsers
                            }
                            pageWindowSize={5}
                            showGoToField={false}
                        />
                    </div>
                </>
                )}
                </>
            )}
        </Wrapper>
    )
}

export default TopRecruiterPage