import React, { useEffect, useState } from 'react'
import ReCaptchaV2 from 'react-google-recaptcha'
import Image from 'react-bootstrap/Image'
import { useField , Formik, Field ,Form as FormSubmit } from 'formik'
import * as Yup from 'yup'
import {Button } from '../Admin/styles/UserManagementEditPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col, Form} from 'react-bootstrap'
import '../BecomeRecruiter/styles/BecomeRecuirter.scss'
import {
    Wrapper,
    P
} from '../BecomeRecruiter/styles/BecomeRecuirterPage.styled'

import PeterImage from '../../../../assets/images/on_demand_recruiter/peter-anthony.svg'
import PaulImage from '../../../../assets/images/on_demand_recruiter/paul-campbell.svg'
import LawrenceImage from '../../../../assets/images/on_demand_recruiter/lawrence.jpeg'
import AlexanderImage from '../../../../assets/images/on_demand_recruiter/alexander.png'

const becomeRecruiterValidation = {
    first_name: Yup.string()
      .required('First name is required')
      .test(
        'first name alphabets only',
        'First Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    last_name: Yup.string()
      .required('Last Name is required')
      .test(
        'last name alphabets only',
        'Last Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    company_name: Yup.string().required('Company name is required'),
    email: Yup.string().email('Must be a valid email').required('Email is required'),
    phone_number: Yup.string().required('Phone number is required').test(
        'Invalid Number',
        "Please enter valid phone number[ Either 10 digit number or '+' and up to 13 digit numbers ].",
        function (value) {
            if (value?.trim() === '') return true
            const regex = /^(?=(?:\D*\d){10,13}\D*$)[-( )(+44)(144)]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-.\s]*[-\0-9]{8,10} *$/
            return !(!value?.trim() || regex.test(value?.trim()) === false);
        }
    ),
    //message: Yup.string().required('Phone number is required'),
}


const MyTextField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
      <>
        <Form.Label column sm="2">
        {label}
        </Form.Label>
        <Col sm="10">
            <Field className="form-control" id={field.name} name={field.name} component={props.type} placeholder={props.placeholder}/>
            {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
            ) : null}
        </Col>
      </>
    );
};

const top_recruiter_list = [
    {id:1, first_name: "Paul", rate: "$75 Per Hour", location:"Palo Alto, California, United States", name:"Paul Campbell", about:"Paul is a hardworking and detail oriented full-stack web architect with 12 years experience. He is as comfortable working with both startups and enterprise solutions and focus on directed growth, technical solutions to reduce bottlenecks and creative strategy.", link_name: "Paul_Campbell", image:PaulImage, skills:['Recruiting','Cloud Computing','Software Development','SaaS','Start-ups','Product Management']},
    {id:2, first_name: "Peter", rate: "$75 Per Hour", location:"New York", name:"Peter Anthony", about:"Peter is a hardworking and detail oriented full-stack web architect with 12 years experience. He is as comfortable working with both startups and enterprise solutions and focus on directed growth, technical solutions to reduce bottlenecks and creative strategy.", link_name: "Peter_Anthony", image:PeterImage, skills:['Recruiting','Cloud Computing','Leadership','Teamwork','Human Resources (HR)','Product Management','Data Analysis']},
    {id:3, first_name: "Lawrence", rate: "$75 Per Hour", location:"New York City Metropolitan Area", name:"Lawrence Wang", about:"I lead an all star recruiting team supporting multiple industries including fintech, financial services, biotech, SaaS, and more!Have helped numerous people find their next home, let’s find yours!", link_name: "Lawrence_Wang", image:LawrenceImage, skills:['Recruiting','Communication','Leadership','Teamwork','Human Resources (HR)','Technical Recruiting','Management','Social Media Marketing']},
    {id:4, first_name: "Alexander", rate: "$75 Per Hour", location:"San Francisco Bay Area", name:"Alexander Lorimer", about:"I work with world-class startups and tech companies to get them the talent that will help secure their growth. Full-Cycle experience in sourcing, screening, scheduling, reference checks, assisting with the offer process, and improving / streamlining the hiring process.", link_name: "Alexander_Lorimer", image:AlexanderImage , skills:['Recruiting','Leadership','Data Analysis','Laboratory Skills','Python','LabVIEW']}
];

const RecruiterContactPage = ({currentUser,recruiterId,user,type}) => {

    const validationSchema = Yup.object({ ...becomeRecruiterValidation })
    const [token, setToken] = useState('')
    const [recruiterDetails, setProfile] = useState('')
    const handleContactUs = (data,resetForm) => {
        let URL = '/recruiter/sendContact'
        let method = 'post'
        const payload = new FormData()
        payload.append('contact[first_name]', data.first_name)
        payload.append('contact[last_name]', data.last_name)
        payload.append('contact[email]',data.email)
        payload.append('contact[phone_number]',data.phone_number)
        payload.append('contact[message]',data?.message)
        payload.append('contact[recruiter_name]',recruiterDetails?.name)
        payload.append('contact[recruiter_id]',recruiterDetails?.id)
        payload.append('contact[token]',token)
        makeRequest(URL,'post',payload , {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                if(responce['messageType'] == 'failure'){
                    return {
                        message: responce['msg'],
                        messageType: responce['messageType'],
                        loading: false,
                        autoClose: true,
                    }
                }else{
                    return {
                        message: `Contact Request Send Successfully.`,
                        messageType: 'success',
                        loading: false,
                        autoClose: true,
                    }
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: (responce) => {
                window.grecaptcha.reset();
                if(responce['data']['messageType'] == 'success'){
                    resetForm();
                }
                //window.location.href = '/cardinal/employees'
            },
        })
    }

    const handleToken = (token) => {
        setToken(token);
        setForm((currentForm) => {
         return {...currentForm, token }
        })
    }

    const handleExpire = () => {
        setForm((currentForm) => {
            return {...currentForm, token: null }
        })
    }

    useEffect(() => {
        if(type == "Home"){
            setProfile(top_recruiter_list[recruiterId-1]);
        }else{

            const profile_data =  {
                id:user.id, 
                rate: "$"+user?.cr_recruiter_detail?.rate+" Per Hour", 
                location:user.location?.replace('(usa)','(US)'),
                first_name:user.first_name,
                name:user.first_name+" "+user.last_name,
                image: user.image_url ? user.image_url : user.ct_profile_pic, 
                about:user?.cr_recruiter_detail?.about, 
            }
            setProfile(profile_data);
        }
    }, []);
    
    return (
        <Wrapper>
            <div className="formLayout">
                <div className="innerForm">
                    <div className="col-xl-8 col-lg-6 col-md-8 col-sm-10 form">
                        <div className="d-flex">
                            <div className="image">
                                <Image src={recruiterDetails?.image} style={{ height: '175px',width: '175px' }}/>
                            </div>
                            <div className="ml-4 w-100">
                                <div className="p-2 d-flex justify-content-between rounded stats">
                                    <h4 className="mb-0 mt-0">{recruiterDetails?.name}</h4>
                                </div>
                                <div className="p-2 d-flex justify-content-between rounded stats">
                                    <h4 className="mb-0 mt-0">{recruiterDetails?.rate}</h4>
                                </div>
                                <div className="p-2 d-flex justify-content-between rounded stats">
                                    <h4 className="mb-0 mt-0">{recruiterDetails?.location}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <P size="40px" height="40px" marginTop="30px" marginBottom="40px" style={{ maxWidth: '90%'}}>
                                Send Message To {recruiterDetails?.first_name}
                            </P>
                        </div>
                        <Formik
                            initialValues={{
                                first_name: currentUser?.first_name,
                                last_name: currentUser?.last_name,
                                company_name: currentUser?.company_name,
                                email: currentUser?.email, 
                                phone_number: currentUser?.phone_number,
                                message: '',
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting ,resetForm }) => {
                                console.log(values)
                                setTimeout(() => {
                                    setSubmitting(false);
                                    handleContactUs(values,resetForm);
                                }, 400);
                            }}
                        >
                            <FormSubmit>
                                <Form.Group as={Row} className="mb-3" controlId="formFirstName">
                                    <MyTextField name="first_name" type="input" label="First Name*" placeholder="First Name"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formLastName">
                                    <MyTextField name="last_name" type="input" label="Last Name*" placeholder="Last Name"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formCompanyName">
                                    <MyTextField name="company_name" type="input" label="Company Name*" placeholder="Company Name"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                    <MyTextField name="email" type="input" label="Email*" placeholder="Email"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formNumber">
                                    <MyTextField name="phone_number" type="input" label="Phone Number*" placeholder="Phone Number"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formLastName">
                                    <MyTextField name="message" type="textarea" label="Message" placeholder="Message"/>
                                </Form.Group>
                                <ReCaptchaV2 
                                    sitekey='6Lf0VJIgAAAAADFu4GqYE5dILecwOI8w_-OutOde'
                                    onChange={handleToken}
                                    onExpire={handleExpire}
                                />
                                <Row>
                                    <Col>
                                        <div style={{ marginTop: '18px' }}>
                                            <Button type="submit">Send</Button>
                                            <Button
                                    type="button"
                                    onClick={() => window.history.back()}
                                    className="ml-sm-3"
                                >
                                    Go Back
                                </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </FormSubmit>
                        </Formik>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default RecruiterContactPage