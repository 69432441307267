import React, { useState } from 'react'
import ReCaptchaV2 from 'react-google-recaptcha'
import { useField , Formik, Field ,Form as FormSubmit } from 'formik'
import * as Yup from 'yup'
import {Button } from '../Admin/styles/UserManagementEditPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { Row, Col, Form} from 'react-bootstrap'
import '../BecomeRecruiter/styles/BecomeRecuirter.scss'
import {
    Wrapper,
    P
} from '../BecomeRecruiter/styles/BecomeRecuirterPage.styled'

const becomeRecruiterValidation = {
    first_name: Yup.string()
      .required('First name is required')
      .test(
        'first name alphabets only',
        'First Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    last_name: Yup.string()
      .required('Last Name is required')
      .test(
        'last name alphabets only',
        'Last Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    email: Yup.string().email('Must be a valid email').required('Email is required'),
    phone_number: Yup.string().required('Phone number is required').test(
        'Invalid Number',
        "Please enter valid phone number[ Either 10 digit number or '+' and up to 13 digit numbers ].",
        function (value) {
            if (value?.trim() === '') return true
            const regex = /^(?=(?:\D*\d){10,13}\D*$)[-( )(+44)(144)]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-.\s]*[-\0-9]{8,10} *$/
            return !(!value?.trim() || regex.test(value?.trim()) === false);
        }
    ),
    //message: Yup.string().required('Phone number is required'),
}


const MyTextField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
      <>
        <Form.Label column sm="2">
        {label}
        </Form.Label>
        <Col sm="10">
            <Field className="form-control" id={field.name} name={field.name} component={props.type} placeholder={props.placeholder}/>
            {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
            ) : null}
        </Col>
      </>
    );
};



const ContactUsPage = () => {

    const validationSchema = Yup.object({ ...becomeRecruiterValidation })
    const [token, setToken] = useState('')
    const handleContactUs = (data,resetForm) => {
        let URL = '/recruiter/contact_us'
        let method = 'post'
        const payload = new FormData()
        payload.append('contact[first_name]', data.first_name)
        payload.append('contact[last_name]', data.last_name)
        payload.append('contact[email]',data.email)
        payload.append('contact[phone_number]',data.phone_number)
        payload.append('contact[message]',data?.message)
        payload.append('contact[token]',token)
        makeRequest(URL,'post',payload , {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                if(responce['messageType'] == 'failure'){
                    return {
                        message: responce['msg'],
                        messageType: responce['messageType'],
                        loading: false,
                        autoClose: true,
                    }
                }else{
                    return {
                        message: `Contact Request Send Successfully.`,
                        messageType: 'success',
                        loading: false,
                        autoClose: true,
                    }
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: (responce) => {
                window.grecaptcha.reset();
                if(responce['data']['messageType'] == 'success'){
                    resetForm();
                }
                //window.location.href = '/cardinal/employees'
            },
        })
    }

    const handleToken = (token) => {
        setToken(token);
        setForm((currentForm) => {
         return {...currentForm, token }
        })
    }

    const handleExpire = () => {
        setForm((currentForm) => {
            return {...currentForm, token: null }
        })
    }
    
    return (
        <Wrapper>
            {/* <TeamSection>
                <div className="d-flex flex-column align-items-center">
                    <P size="40px" height="40px" marginTop="25px">
                        Contact Us
                    </P>
                </div>
            </TeamSection> */}
            <div className="formLayout">
                <div className="innerForm">
                    {/* <div className="d-flex flex-column">
                        <P size="40px" height="40px" marginBottom="40px">
                            Contact Us
                        </P>
                    </div> */}
                    <div className="col-xl-8 col-lg-6 col-md-8 col-sm-10 mx-auto form">
                        <div className="d-flex flex-column">
                            <P size="40px" height="40px" marginBottom="40px">
                                Contact Us
                            </P>
                        </div>
                        <Formik
                            initialValues={{
                                first_name: '',
                                last_name: '',
                                email: '', 
                                phone_number: '',
                                message: '',
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting ,resetForm }) => {
                                //console.log(values)
                                setTimeout(() => {
                                    setSubmitting(false);
                                    handleContactUs(values,resetForm);
                                }, 400);
                            }}
                        >
                            <FormSubmit>
                                <Form.Group as={Row} className="mb-3" controlId="formFirstName">
                                    <MyTextField name="first_name" type="input" label="First Name*" placeholder="First Name"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formLastName">
                                    <MyTextField name="last_name" type="input" label="Last Name*" placeholder="Last Name"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                    <MyTextField name="email" type="input" label="Email*" placeholder="Email"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formNumber">
                                    <MyTextField name="phone_number" type="input" label="Phone Number*" placeholder="Phone Number"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formLastName">
                                    <MyTextField name="message" type="textarea" label="Message" placeholder="Message"/>
                                </Form.Group>
                                <ReCaptchaV2 
                                    sitekey='6Lf0VJIgAAAAADFu4GqYE5dILecwOI8w_-OutOde'
                                    onChange={handleToken}
                                    onExpire={handleExpire}
                                />
                                <Row>
                                    <Col>
                                        <div style={{ marginTop: '18px' }}>
                                            <Button type="submit">Send</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </FormSubmit>
                        </Formik>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default ContactUsPage