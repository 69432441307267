import React, { Fragment } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Input from '../../common/Styled components/Input'
import { Row, W3text, Typography } from './styles/AccountSettingPage.styled'
import styles from './styles/AccountSettingPage.module.scss'

const Schema = {
    firstName: Yup.string()
        .required('First Name is required')
        .test(
            'first name alphabets only',
            'First Name can only contain alphabet characters and one space in between if there are two words',
            function (value) {
                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                return regex.test(value)
            }
        ),

    lastName: Yup.string()
        .required('Last Name is required')
        .test(
            'last name alphabets only',
            'Last Name can only contain alphabet characters and one space in between if there are two words',
            function (value) {
                const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                return regex.test(value)
            }
        ),
    companyName: Yup.string()
        .required('Company Name is required'),
    phoneNumber: Yup.string().required('Phone Number is required').test(
        'Invalid Number',
        "Please enter valid phone number[ Either 10 digit number or '+' and up to 13 digit numbers ].",
        function (value) {
            if (value?.trim() === '') return true
            const regex = /^(?=(?:\D*\d){10,13}\D*$)[-( )(+44)(144)]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-.\s]*[-\0-9]{8,10} *$/
            return !(!value?.trim() || regex.test(value?.trim()) === false);
        }
    ),
}
const linkedinProfileUrlScema = Yup.string()
    .required('Linkedin Profile is required')
    .nullable(true)
    .test(
        'linkedin only',
        'Invalid url, please add Linkedin url only',
        function (value) {
            try {
                let hostname = new URL(value).hostname
                finalLinkedInURL = value
                if(value === 'https://www.linkedin.com' || value === 'https://www.linkedin.com/'){
                    return false
                }
                return (
                    hostname === 'linkedin.com' || hostname === 'www.linkedin.com'
                )
            } catch (error) {
                finalLinkedInURL = value
                if(value === 'www.linkedin.com/' || value === 'https://www.linkedin.com/' || value === 'linkedin.com/' || value === 'linkedin.com' || value === 'https://linkedin.com' || value === 'https://linkedin.com/'){
                    return false
                }
                let checkDomainExist = value.indexOf("linkedin.com/")
                if(checkDomainExist == -1){
                    return false
                }else if(checkDomainExist == 0){
                    let splitValue = value.split('linkedin.com/')
                    if(splitValue[1] === ''){ 
                        return false
                    }
                    finalLinkedInURL = 'https://www.'+ value
                    return true
                }else{
                    let splitValue = value.split('linkedin.com/')
                     if(splitValue[0] === 'www.'){
                        finalLinkedInURL = 'https://'+ value
                        return true
                     }
                }
                return false
            }
        }
    )

const talentSchema = {
    ...Schema,
    linkedinProfileUrl: linkedinProfileUrlScema,
}

let finalLinkedInURL = ''

function EditAccountSetting(props) {
    const {
        firstName,
        lastName,
        email,
        handleSaveBasic,
        phoneNumber,
        companyName,
        linkedinProfileUrl,
        role,
        setIsEditBasicInfo
    } = props

  const validationSchema = role === 'talent' ? Yup.object({...talentSchema}) :Yup.object({...Schema})

    return (
        <>
            <Formik
                initialValues={{
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    companyName: companyName,
                    linkedinProfileUrl: linkedinProfileUrl,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        setSubmitting(false)
                    }, 500)
                    values.linkedinProfileUrl = finalLinkedInURL
                    handleSaveBasic(values)
                }}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                    } = props
                    return (
                        <Fragment>
                            <Row aItems="center">
                                <label>First Name*</label>
                                <Input
                                    type="text"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    name="firstName"
                                    width="40%"
                                    error={
                                        Boolean(errors.firstName) &&
                                        touched.firstName
                                    }
                                />
                                {errors.firstName && touched.firstName && (
                                    <Typography>{errors.firstName}</Typography>
                                )}
                            </Row>
                            <Row aItems="center">
                                <label>Last Name*</label>
                                <Input
                                    type="text"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    name="lastName"
                                    width="40%"
                                    error={
                                        Boolean(errors.lastName) &&
                                        touched.lastName
                                    }
                                />
                                {errors.lastName && touched.lastName && (
                                    <Typography>{errors.lastName}</Typography>
                                )}
                            </Row>
                            <Row aItems="center">
                                <label>Company Name*</label>
                                <Input
                                    type="text"
                                    value={values.companyName}
                                    onChange={handleChange}
                                    name="companyName"
                                    width="40%"
                                    error={
                                        Boolean(errors.companyName) &&
                                        touched.companyName
                                    }
                                />
                                {errors.companyName && touched.companyName && (
                                    <Typography>{errors.companyName}</Typography>
                                )}
                            </Row>

                            <Row aItems="center">
                                <label>Email</label>
                                <p style={{ marginRight: '20px' }}>
                                    {email + '    '}{' '}
                                </p>
                                <W3text color="#5F73D9" size="">
                                    {' '}
                                    (We do not currently support changing email
                                    address){' '}
                                </W3text>
                            </Row>
                            {role === 'talent' && (
                                <>
                                    <Row aItems="center">
                                        <label>Linkedin profile</label>
                                        <Input
                                            type="text"
                                            value={values.linkedinProfileUrl}
                                            onChange={handleChange}
                                            name="linkedinProfileUrl"
                                            width="40%"
                                            error={
                                                Boolean(
                                                    errors.linkedinProfileUrl
                                                ) && touched.linkedinProfileUrl
                                            }
                                        />
                                        {errors.linkedinProfileUrl &&
                                            touched.linkedinProfileUrl && (
                                                <Typography>
                                                    {errors.linkedinProfileUrl}
                                                </Typography>
                                            )}
                                    </Row>
                                </>
                            )}
                            <Row aItems="center">
                                <label>Phone Number*</label>
                                <Input
                                    type="text"
                                    value={(values.phoneNumber)?.replace('(','').replace(')','').replace('-','').replace(' ','').trim()}
                                    onChange={handleChange}
                                    name="phoneNumber"
                                    width="40%"
                                    error={
                                        Boolean(errors.phoneNumber) &&
                                        touched.phoneNumber
                                    }
                                />
                                {errors.phoneNumber && touched.phoneNumber && (
                                    <Typography>
                                        {errors.phoneNumber}
                                    </Typography>
                                )}
                            </Row>
                            <Row aItems="center">
                                <button
                                    type='submit'
                                    className={`${styles.editButton}`}
                                    onClick={handleSubmit}
                                >
                                    Save
                                </button>
                                <button
                                    className={`${styles.editButton}`}
                                    onClick={()=>setIsEditBasicInfo(false)}
                                >
                                    Cancel
                                </button>
                            </Row>
                        </Fragment>
                    )
                }}
            </Formik>
        </>
    )
}

export default EditAccountSetting
