import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'react-bootstrap'
import axios from 'axios'
import _ from 'lodash'

import TextInput from '../../common/TextInput/TextInput'
import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import FileButton from '../../common/FileButton/FileButton'
import FormRadioInput from '../../common/FormRadioInput/FormRadioInput'
import styles from '../SignupPage/styles/Signup.module.scss'
import PhoneInput from '../../common/PhoneInput/PhoneInput'
import { normalizeInput } from '../../../utils'
import CreatableSelect from 'react-select/creatable'
import {
    H1,
    Label,
    Message,
    P,
    StyledForm,
    Button,
    Row,
    ErrorMessage,
} from './styles/CreateProfilePage.styled'

const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
const CHOOSE_ROLE = 'CHOOSE_ROLE'

const colourStyles = {
    control: (styles) => ({
        ...styles,
        width: '345px',
        height: '43px',
        marginTop: '1.5px',
        backgroundColor: '#f5f7ff',
        border: 'none',
        zIndex: '15',
    }),
}

const ContactDetailPage = ({
    formData,
    setFormData,
    submitData,
    loading,
    setResult,
    result,
    selectedRole,
    user,
    showInfoPanel
}) => {
    const [roleDescription, setRoleDescription] = useState('')
    const [resume, setResume] = useState(formData.contactDetails.resume)
    const [resumeError, setResumeError] = useState(null)
    const [states, setStates] = useState([])
    const [phoneNumber, setPhoneNumber] = useState(
        formData.contactDetails.phoneNumber
    )
    const [selectedLocation, setSelectedLocation] = useState('')
    const [locationOptions, setLocationOptions] = useState([])
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [appendLinkedinValue, setAppendLinkedinValue] = useState(formData.contactDetails.linkedinProfile)

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            contactDetails: {
                ...prev.contactDetails,
                resume: resume,
            },
        }))
    }, [resume])

    useEffect(() => {
        fetchRequired()
    }, [])

    useEffect(() => {
        setResult({})
        if (isSubmitted) {
            if (selectedRole === 'talent') {
                if (!resume) {
                    setResumeError('Resume is required')
                    return
                }
                submitData()
            } else if (selectedRole === 'recruiter') {
                submitData()
            } else {
                if (user.organization_id) {
                    submitData()
                } else {
                    setFormData((prev) => ({
                        ...prev,
                        step: 'CREATE_ORGANIZATION',
                    }))
                }
            }
        }
        setIsSubmitted(false)
    }, [isSubmitted])

    const fetchRequired = async () => {
        const url = `/signup/contracts?name=contact_note&role=${selectedRole}`
        axios.get(url).then((res) => {
            setRoleDescription(res.data?.content)
        })

        const lookupsUrl = '/signup/lookups'
        axios.get(lookupsUrl).then((res) => {
            setStates([...res.data.states])
        })

        const url1 = `/filter_candidate_on_location`
        const formData = new FormData()
        formData.append('filter_word', '')
        const response = await axios
            .post(url1, formData)
            .then((res) => res.data)
            .catch((error) => console.log(error))
        const locationArray = response.filter.map((res) => ({
            value: `${res.city}, ${res.state}, (${res.country})`,
            label: `${res.city}, ${res.state}, (${res.country})`,
        }))
        setLocationOptions([...locationArray])
    }

    const employerFields = {
        firstName: formData.contactDetails.firstName,
        lastName: formData.contactDetails.lastName,
        title: formData.contactDetails.title,
        phoneNumber: phoneNumber,
        streetAddress: formData.contactDetails.streetAddress,
        state: formData.contactDetails.state,
        zipCode: formData.contactDetails.zipCode,
    }

    const recruiterFileds = {
        firstName: formData.contactDetails.firstName,
        lastName: formData.contactDetails.lastName,
        location: selectedLocation,
        phoneNumber: phoneNumber,
        resume: formData.contactDetails.resume,
    }

    const candidateFields = {
        firstName: formData.contactDetails.firstName,
        lastName: formData.contactDetails.lastName,
        linkedinProfile: formData.contactDetails.linkedinProfile,
        phoneNumber: phoneNumber,
        resume: formData.contactDetails.resume,
        activeJobSeeker: '',
    }

    const employerValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Must be exactly 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),

        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Must be exactly 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        title: Yup.string().required('Title is required'),
        streetAddress: Yup.string().required('Company Address is required'),
        state: Yup.string().required('State is required'),
        zipCode: Yup.string()
            .required('Zip Code is required')
            .test(
                'zip code digits only',
                'Zip code must contain 5 digits only',
                function (value) {
                    const regex = /^\d{5}$/g
                    return regex.test(value)
                }
            ),
    })

    const recruiterValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Must be exactly 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Must be exactly 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
    })

    const candidateValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        linkedinProfile: Yup.string()
            .required('Linkedin Profile is required')
            .test(
                'linkedin only',
                'Invalid url, please add Linkedin url only',
                function (value) {
                    try {
                        let hostname = new URL(value).hostname
                        setAppendLinkedinValue(value)
                        if(value === 'https://www.linkedin.com' || value === 'https://www.linkedin.com/'){
                            return false
                        }
                        return (
                            hostname === 'linkedin.com' || hostname === 'www.linkedin.com'
                        )
                    } catch (error) {
                        setAppendLinkedinValue(value)
                        if(value === 'www.linkedin.com/' || value === 'https://www.linkedin.com/' || value === 'linkedin.com/' || value === 'linkedin.com' || value === 'https://linkedin.com' || value === 'https://linkedin.com/'){
                            return false
                        }
                        let checkDomainExist = value.indexOf("linkedin.com/")
                        if(checkDomainExist == -1){
                            return false
                        }else if(checkDomainExist == 0){
                            let splitValue = value.split('linkedin.com/')
                            if(splitValue[1] === ''){ 
                                return false
                            }
                            let tempURL = 'https://www.'+ value
                            setAppendLinkedinValue(tempURL)
                            return true
                        }else{
                            let splitValue = value.split('linkedin.com/')
                             if(splitValue[0] === 'www.'){
                                let tempURL = 'https://'+ value
                                setAppendLinkedinValue(tempURL)
                                 return true
                             }
                        }
                        return false
                    }
                }
            ),
        activeJobSeeker: Yup.string().required(
            'Please select only one checkbox'
        ),
    })

    const handleChange = ({ target: { value } }) => {
        setPhoneNumber(normalizeInput(value, ''))
    }
    const locationChange = (e) => {
        setSelectedLocation(e.value)
    }

    const handleSubmit = async (values) => {
        if (formData.selectedRole === 'talent' && !resume) {
            setResumeError('Resume is required')
            return
        }
        if (phoneNumber.length != 0 && phoneNumber.length != 14) {
            return
        }
        if (
            formData.selectedRole === 'recruiter' &&
            _.isEmpty(selectedLocation)
        ) {
            return
        }

        if(values.linkedinProfile){
            values.linkedinProfile = appendLinkedinValue
        }
        setFormData((prev) => ({
            ...prev,
            contactDetails: {
                ...prev.contactDetails,
                ...values,
                resume: resume,
                phoneNumber: phoneNumber,
                location: selectedLocation,
            },
        }))
        setIsSubmitted(true)
    }

    return (
        <>
            <div className={`${styles.signUpForm}`}>
                <MainPanel>
                    <H1>
                        {selectedRole === 'employer'
                            ? 'Contact Person Details'
                            : 'Contact Details'}
                    </H1>

                    <Formik
                        initialValues={
                            selectedRole === 'employer'
                                ? employerFields
                                : selectedRole === 'recruiter'
                                ? recruiterFileds
                                : candidateFields
                        }
                        validationSchema={
                            selectedRole === 'employer'
                                ? employerValidation
                                : selectedRole === 'recruiter'
                                ? recruiterValidation
                                : candidateValidation
                        }
                        validate={(values) => {
                            const errors = {}

                            if (selectedRole === 'talent' && !resume) {
                                errors.resume = 'Resume is required'
                                setResumeError(errors.resume)
                            }
                            if (
                                phoneNumber.length != 0 &&
                                phoneNumber.length != 14
                            ) {
                                errors.phoneNumber = 'Accepts only 10 digits'
                            }
                            if (
                                selectedRole === 'recruiter' &&
                                _.isEmpty(selectedLocation)
                            ) {
                                errors.streetAddress = 'Location is required'
                            }

                            return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setFormData({
                                ...formData,
                                contactDetails: {
                                    ...values,
                                    phoneNumber: phoneNumber,
                                    resume: resume,
                                },
                            })
                            handleSubmit(values)
                        }}
                    >
                        {(formik) => {
                            const { errors } = formik
                            return (
                                <StyledForm className={styles.mainoragiForm}>
                                    <TextInput
                                        className={styles.contactDetailInpu}
                                        label="First Name*"
                                        name="firstName"
                                        type="text"
                                        id="firstName"
                                        width="20rem"
                                        maxLength={50}
                                    />
                                    <TextInput
                                        className={styles.contactDetailInpu}
                                        label="Last Name*"
                                        name="lastName"
                                        type="text"
                                        id="lastName"
                                        width="20rem"
                                        maxLength={50}
                                        style={{
                                            marginLeft: '10px',
                                        }}
                                    />
                                    {selectedRole === 'employer' ? (
                                        <>
                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                label="Title*"
                                                name="title"
                                                type="text"
                                                id="title"
                                                width="20rem"
                                                maxLength={200}
                                            />
                                            <PhoneInput
                                                label="Phone #"
                                                name="phoneNumber"
                                                type="text"
                                                id="phoneNumber"
                                                placeholder="(xxx) xxx-xxxx"
                                                width="20rem"
                                                onChange={handleChange}
                                                value={phoneNumber}
                                                style={{ marginLeft: '10px' }}
                                            />
                                        </>
                                    ) : selectedRole === 'recruiter' ? (
                                        <>
                                            <div
                                                style={{
                                                    width: '345px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Label>{'Location*'}</Label>
                                                <CreatableSelect
                                                    name="streetAddress"
                                                    id="streetAddress"
                                                    placeholder="Location"
                                                    isClearable
                                                    onChange={(e) =>
                                                        locationChange(e)
                                                    }
                                                    options={locationOptions}
                                                    styles={colourStyles}
                                                />
                                                <ErrorMessage>
                                                    {errors.streetAddress}
                                                </ErrorMessage>
                                            </div>
                                            <PhoneInput
                                                label="Phone #"
                                                name="phoneNumber"
                                                type="text"
                                                id="phoneNumber"
                                                placeholder="(xxx) xxx-xxxx"
                                                width="20rem"
                                                onChange={handleChange}
                                                value={phoneNumber}
                                                style={{ marginLeft: '10px' }}
                                            />
                                        </>
                                    ) : null}

                                    {selectedRole === 'employer' && (
                                        <>
                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                label="Company Address*"
                                                name="streetAddress"
                                                type="text"
                                                id="streetAddress"
                                                width="20rem"
                                            />
                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                as="select"
                                                label="State*"
                                                name="state"
                                                type="text"
                                                id="state"
                                                width="8rem"
                                                style={{
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                <option value=""></option>
                                                {states.map(
                                                    ({ key, value }) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {value}
                                                            </option>
                                                        )
                                                    }
                                                )}
                                            </TextInput>

                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                label="Zip Code*"
                                                name="zipCode"
                                                type="text"
                                                id="zipCode"
                                                width="10rem"
                                                style={{
                                                    marginLeft: '10px',
                                                }}
                                            />
                                        </>
                                    )}

                                    {selectedRole === 'recruiter' && (
                                        <FileButton
                                            type="button"
                                            label="Upload Resume"
                                            file={resume}
                                            resumeError={resumeError}
                                            setResumeError={setResumeError}
                                            getFile={(file) => setResume(file)}
                                        />
                                    )}

                                    {selectedRole === 'talent' && (
                                        <>
                                            <div className={styles.uplodDiv}>
                                                <div
                                                    className={styles.uplodDivs}
                                                >
                                                    <FileButton
                                                        label="Upload Resume"
                                                        type="button"
                                                        width={321}
                                                        file={resume}
                                                        resumeError={
                                                            resumeError
                                                        }
                                                        setResumeError={
                                                            setResumeError
                                                        }
                                                        getFile={(file) =>
                                                            setResume(file)
                                                        }
                                                    />
                                                </div>
                                                <TextInput
                                                    className={
                                                        styles.formDetailswidth
                                                    }
                                                    label="Linkedin Profile*"
                                                    name="linkedinProfile"
                                                    type="text"
                                                    id="linkedinProfile"
                                                    width="20rem"
                                                    style={{
                                                        marginLeft: '10px',
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.phoneDiv}>
                                                <PhoneInput
                                                    label="Phone #"
                                                    name="phoneNumber"
                                                    type="text"
                                                    id="phoneNumber"
                                                    placeholder="(xxx) xxx-xxxx"
                                                    width="20rem"
                                                    onChange={handleChange}
                                                    value={phoneNumber}
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                />
                                                <div
                                                    className={
                                                        styles.formDetailscheckbox
                                                    }
                                                >
                                                    <p>
                                                        Are you an active job
                                                        seeker?
                                                    </p>
                                                    <FormRadioInput
                                                        name="activeJobSeeker"
                                                        id="activeJobSeeker"
                                                        value="Yes, I am actively searching for a
                                            job"
                                                    >
                                                        Yes, I am actively
                                                        searching for a job
                                                    </FormRadioInput>
                                                    <FormRadioInput
                                                        name="activeJobSeeker"
                                                        id="activeJobSeeker"
                                                        value="No, but I am open to the right
                                            opportunity"
                                                    >
                                                        No, but I am open to the
                                                        right opportunity
                                                    </FormRadioInput>
                                                    <FormRadioInput
                                                        name="activeJobSeeker"
                                                        id="activeJobSeeker"
                                                        value="No, I am just browsing"
                                                    >
                                                        No, I am just browsing
                                                    </FormRadioInput>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <Row jContent="flex-end" mt="18px">
                                        <Button
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <Spinner
                                                    animation="border"
                                                    variant="light"
                                                />
                                            ) : selectedRole === 'employer' &&
                                              !user.organization_id ? (
                                                'Next'
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Row>
                                    {result && (
                                        <Row jContent="center" mt="18px">
                                            <Message color={result.color}>
                                                {result.message}
                                            </Message>
                                        </Row>
                                    )}
                                </StyledForm>
                            )
                        }}
                    </Formik>
                </MainPanel>
                
                { showInfoPanel &&
                    <InfoPanel>
                        <div className={styles.signUpdetails}>
                            <div className="d-flex flex-column">
                                <P>Note</P>
                                <div
                                    style={{
                                        border: '1px solid #BFC5E2',
                                        width: '100%',
                                        marginBottom: '30px',
                                    }}
                                ></div>
                                <P
                                    weight={'normal'}
                                    dangerouslySetInnerHTML={{
                                        __html: roleDescription,
                                    }}
                                ></P>
                            </div>
                        </div>
                    </InfoPanel>
                }
            </div>
        </>
    )
}

export default ContactDetailPage
