import React from 'react'
import Image from 'react-bootstrap/Image'

import ImageOne from '../../../../assets/images/about_us_page_assets/about-us.png'
import {
    Wrapper,
    AboutUsSection,
    AboutUsText,
    P,
    TeamSection,
    Line,
    Description,
    Button,
    LookingForWorkSection,
} from './styles/AboutUsPage.styled'

const AboutUsPage = ({ description }) => {
    return (
        <Wrapper>
            <TeamSection>
                <div className="d-flex flex-column align-items-center">
                    <P size="40px" height="40px" marginTop="25px">
                       About Us
                    </P>
                </div>
            </TeamSection>
            {/* <Description
                weight="normal"
                dangerouslySetInnerHTML={{ __html: description }}
            /> */}
        </Wrapper>
    )
}

export default AboutUsPage