import React from 'react'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ImageOne from '../../../../assets/images/recruiter_page_assets/recruiter-landing.png'
import RightArrow from '../../../../assets/images/recruiter_page_assets/arrow-right-long.svg'
import './styles/RecruiterPage.scss'

import {
    H1,
    P,
    Button,
    CONTAINER,
    GRID,
    COL,
    A,
} from './styles/RecruiterPage.styled'

const RecruiterPage = ({ver}) => {
    return (
        <>
            {ver == 2 &&
            <section style={{ background: '#F9FAFF' }}>
                <Row className="mx-0 main-find">
                    <Col className="d-flex flex-column flex-wrap align-items-center justify-content-center mb-2">
                        <div className="find-details">
                            <H1 className="find-tittle">
                                Learn how to be a Technical Recruiter <br />& how to get recruiting clients
                            </H1>
                            {/* <P>Pinterest , Snapchat , Others</P> */}
                        </div>
                    </Col>
                    <Col className="pr-0 d-flex justify-content-end">
                        {/* <Image src={ImageOne} className="img-fluid" /> */}
                        <Image src="https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/lraqksavoqix2i85dracr8wxr8yl" className="img-fluid" />
                    </Col>
                </Row>
            </section>
            }
            {ver == 1 &&
            <section style={{ background: '#040E27' }}>
                <Row className="mx-0 main-find">
                    <Col className="d-flex flex-column flex-wrap align-items-center justify-content-center mb-2">
                        <div className="find-details">
                            <H1 className="find-tittle white">
                                Learn how to be a Technical Recruiter <br />& how to get recruiting clients
                            </H1> 
                            {/* <P className="white">Pinterest , Snapchat , Others</P> */}
                        </div>
                    </Col>
                    <Col className="pr-0 d-flex justify-content-end">
                        {/* <Image src={ImageOne} className="img-fluid" /> */}
                        <Image src="https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/lraqksavoqix2i85dracr8wxr8yl" className="img-fluid" />
                    </Col>
                </Row>
            </section>
            }
            {ver == 3 &&
            <section className="main-area section-gap" style={{padding: "60px"}}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-12 ">
                            <H1 className="find-tittle" style={{textAlign: 'center',fontSize: "50px"}}>
                                Learn how to be a Technical Recruiter <br /> & how to get recruiting clients
                            </H1>
                            {/* <P style={{textAlign: 'center',fontSize: "30px",marginTop: "15px"}}>Pinterest , Snapchat , Others</P> */}
                        </div>
                    </div>
                </div>
            </section>
            }
            <section className="about-area section-gap">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6 about-left">
                            <img className="img-fluid" src="https://cardinaltalent-prod.s3.us-east-2.amazonaws.com/lraqksavoqix2i85dracr8wxr8yl" alt="" />
                        </div>
                        <div className="col-lg-5 col-md-12 about-right">
                            <div className="section-title">
                                <h2>about me</h2>
                            </div>
                            <div className="mb-50 wow fadeIn" style={{visibility: 'visible', animationDuration: '0.8s', animationName: 'fadeIn'}}>
                                <p>My name is Paul Campbell and I have a background in Engineering from Stanford and UC Berkeley.</p>
                                <p>I have over 10 years of experience as a technical recruiter and I have generated over $10,000,000 in recruiting revenue.</p>
                                <p>I have taught hundreds of recruiters and have helped people create their own businesses from scratch that now generate millions of dollars in revenue.</p>
                                <p>I have also helped people become Directors of Recruiting at world-class companies including Pinterest , Snapchat and Others.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-area section-gap" style={{padding: '20px 0'}}> 
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12 about-right">
                            <div className="section-title">
                                <h2>What Services you will Get from me!</h2>
                            </div>
                            <div className="section-block">
                                <div className="container">
                                    <div className="row mt-60">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="serv-section-3">
                                                <div className="serv-section-desc">
                                                    <h3>For $199/month:</h3>
                                                </div>
                                                <div className="serv-section-list">
                                                    <ul className="list-group">
                                                        <li><span>✓</span> Weekly video call with me</li>
                                                        <li><span>✓</span> Learn how to get your own recruiting clients</li>
                                                        <li><span>✓</span> Learn how to recruit candidates</li>
                                                        <li><span>✓</span> Get 1,000 client emails per month</li>
                                                        <li><span>✓</span> Get 1,000 candidate phone numbers per month.</li>
                                                        <li><span>✓</span> Get access to 100s of job orders and rewards</li>
                                                        <li><span>✓</span> Access to our ATS to track your candidates</li>
                                                    </ul>
                                                </div>
                                                <Button
                                                    onClick={() =>
                                                        (window.location.href = '/promotion/plan/monthly')
                                                    }
                                                >
                                                    Book A Call
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="serv-section-3">
                                                <div className="serv-section-desc">
                                                    <h3>Personalized Mentorship:</h3>
                                                </div>
                                                <div className="serv-section-list">
                                                    <ul className="list-group">
                                                        <li><span>✓</span> 1:1 personal mentorship with me or my team</li>
                                                    </ul>
                                                </div>
                                                <Button className="btn-margin-top"
                                                    onClick={() =>
                                                        (window.location.href = '/promotion/plan/personalized')
                                                    }
                                                >
                                                    Book A Call
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="about-area" style={{paddingBottom: '30px'}}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-6 col-sm-12 col-12">
                            <Button
                                onClick={() =>
                                    (window.location.href = '/promotion/plan/monthly')
                                }
                            >
                                Buy Now
                            </Button>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                            <Button
                                onClick={() =>
                                    (window.location.href = '/promotion/plan/personalized')
                                }
                                marginLeft={15}
                            >
                                Buy Now
                            </Button>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default RecruiterPage
