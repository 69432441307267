import React, { useState, useEffect } from 'react'

import CreateOrganizationPage from './CreateOrganizationPage'
import ContactDetailPage from './ContactDetailPage'
import axios from 'axios'

const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
const CONTACT_DETAILS = 'CONTACT_DETAILS'

const initialFormData = {
    step: CONTACT_DETAILS,
    contactDetails: {
        firstName: '',
        lastName: '',
        title: '',
        phoneNumber: '',
        streetAddress: '',
        state: '',
        zipCode: '',
        location: '',
        resume: null,
        linkedinProfile: '',
        activeJobSeeker: '',
    },
    organization: {
        name: '',
        industry: '',
        companySize: '',
        country: '',
        city: null,
        region: null,
        description: '',
        logo: null,
        website_url: '',
    },
}

const CreateProfilePage = ({ user, isProfileComplete, showInfoPanel }) => {
    const [formData, setFormData] = useState(initialFormData)
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(null)
    const isOnlyCreateOrganization = isProfileComplete && user.role === 'employer'
    const [infoPanel, setInfoPanel] = useState(showInfoPanel)

    useEffect(() => {
        setFormData({ ...formData })
        if (isOnlyCreateOrganization) {
            setFormData({ ...formData, step: CREATE_ORGANIZATION })
        }
    }, [user])

    const submitData = async (values) => {
        const data = { ...formData, ...values }
        const payload = new FormData()
        const url = '/create_profile'

        payload.append('registration[first_name]',data.contactDetails.firstName)
        payload.append('registration[last_name]', data.contactDetails.lastName)
        payload.append('registration[phone_number]',data.contactDetails.phoneNumber)
        payload.append('organization[name]', data.organization.name)
        payload.append('organization[description]',data.organization.description)
        payload.append('organization[industry]', data.organization.industry)
        payload.append('organization[company_size]',data.organization.companySize)
        payload.append('organization[country]', data.organization.country)
        payload.append('organization[region]', data.organization.region?.value)
        payload.append('organization[city]', data.organization.city?.value)
        payload.append('organization[website_url]', data.organization.website_url)
        payload.append('organization[subdomain]', data.organization.subdomain)
        payload.append('organization[slug]', data.organization.slug)
        payload.append('organization[logo]', data.organization.logo)

        if(!infoPanel){
           payload.append('submit_from', 'admin')
        }

        if (user.role === 'employer') {
            payload.append('registration[title]', data.contactDetails.title)
            payload.append('registration[address]',data.contactDetails.streetAddress)
            payload.append('registration[zipcode]', data.contactDetails.zipCode)
            payload.append('registration[state]', data.contactDetails.state)
        }
        if (user.role === 'talent') {
            payload.append('registration[resume]', data.contactDetails.resume)
            payload.append('registration[linkedin_profile_url]',data.contactDetails.linkedinProfile)
            payload.append('registration[active_job_seeker]',data.contactDetails.activeJobSeeker)
        }
        
        if (user.role === 'recruiter'){
            payload.append('registration[location]',data.contactDetails.location) 
            if (data.contactDetails.resume){
            payload.append('registration[resume]', data.contactDetails.resume)
            }  
           
        }
       
        const CSRF_Token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')

        setLoading(true)
        axios
            .post(url, payload, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-Token': CSRF_Token,
                },
            })
            .then((res) => {
                const responce = res.data
                if (responce.success) {
                    if (isOnlyCreateOrganization) {
                        setResult({color: '#0f5132',message: 'Organization successfully created',})
                    } else{
                        setResult({ color: '#0f5132', message: responce.msg })
                    }
                    setTimeout(() => {
                        if (!infoPanel){
                            window.location.href = '/admin/organizations'
                        }
                        else if (user.role === 'employer') {
                            window.location.href = '/jobs'
                        } else if (user.role === 'talent') {
                            window.location.href = '/talent_home'
                        } else {
                            window.location.href = '/jobs'
                        }
                        setLoading(false)
                    }, 2000)
                } else {
                    setResult({ color: '#ff1d31', message: responce.msg })
                    setLoading(false)
                }
                
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
            })
    }

    const displaySignupStep = (step) => {
        switch (step) {
            case CONTACT_DETAILS:
                return (
                    <ContactDetailPage
                        formData={formData}
                        setFormData={setFormData}
                        submitData={submitData}
                        loading={loading}
                        result={result}
                        setResult={setResult}
                        selectedRole={user.role}
                        user={user}
                        showInfoPanel={showInfoPanel}
                    />
                )
            case CREATE_ORGANIZATION:
                return (
                    <CreateOrganizationPage
                        formData={formData}
                        setFormData={setFormData}
                        submitData={submitData}
                        loading={loading}
                        result={result}
                        setResult={setResult}
                        selectedRole={user.role}
                        hidePrevious={isOnlyCreateOrganization}
                        showInfoPanel={showInfoPanel}
                    />
                )
            default:
                return null
        }
    }

    return <>{displaySignupStep(formData.step)}</>
}

export default CreateProfilePage
