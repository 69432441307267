import styled from 'styled-components'
import Col from 'react-bootstrap/Col'

export const H1 = styled.h1`
    font-family: inherit;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    // color: #262b41;
    margin-bottom: ${(props) => props.marginBottom || 0}px;
    margin-top: ${(props) => props.marginTop || 0}px;
    color: #002d5b;
    text-transform: capitalize;
`
