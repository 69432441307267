import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Image from 'react-bootstrap/Image'
import { Popover, OverlayTrigger, Dropdown, Modal, Spinner } from 'react-bootstrap'
import feather from 'feather-icons'

import P from './P'

import XIcon from '../../../../../assets/images/icons/x.svg'
import CheckIcon from '../../../../../assets/images/icons/check.svg'

const Card = styled(Popover)`
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  & div.arrow {
    display: none;
  }
`

const A = styled.a`
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #7288ff;
  cursor: pointer;
`

const Input = styled.input`
  background: transparent;
  border-radius: 10px;
  outline: none;
  border: none;
  width: 100%;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #3b3847;
  padding: 8px 20px;
  height: 100%;
  &:focus {
    background: #f6f7fc;
  }
`

const CustomDropdown = styled(Dropdown)`
  & div.dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0;
    width: 100%;
    & a {
      padding: 10px 20px;
      border-radius: 10px;
    }
    & a:hover {
      background: #f6f7fc;
    }
  }
`

const RolePicker = ({ rowValue, updateAction }) => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <A
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </A>
  ))

  const handleSelect = (role, e) => {
    updateAction({ ...rowValue, role })
  }

  return (
    <CustomDropdown>
      <CustomDropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {rowValue.role}
      </CustomDropdown.Toggle>

      <CustomDropdown.Menu>
        <CustomDropdown.Item eventKey="1" disabled>
          Choose Role
        </CustomDropdown.Item>
        <CustomDropdown.Item
          eventKey="third_party_recruiter"
          onSelect={handleSelect}
        >
          Third Party Recruiter
        </CustomDropdown.Item>
        <CustomDropdown.Item
          eventKey="internal_recruiter"
          onSelect={handleSelect}
        >
          Internal Recruiter
        </CustomDropdown.Item>
        <CustomDropdown.Item eventKey="talent" onSelect={handleSelect}>
          Talent
        </CustomDropdown.Item>
      </CustomDropdown.Menu>
    </CustomDropdown>
  )
}

const xTable = ({
  activePage,
  addNewRow,
  showEditOption,
  saveAction,
  deleteAction,
  columNames = [],
  rowValues = [],
  goToEditPage,
  multipleIdsFunc,
  perPage=25,
  totalValues,
  loading
}) => {
  const [action, setAction] = useState(null)
  const [editMode, setEditMode] = useState(addNewRow)
  const [isUpload, setIsUpload] = useState(null)

  useEffect(() => {
    setEditMode(addNewRow)
    if (addNewRow) {
      setAction({
        type: 'edit',
        data: rowValues[rowValues.length - 1],
      })
    }
  }, [addNewRow])

  useEffect(() => {
    feather.replace()
  })

  const handleClose = () => {
    setAction(null)
  }
  const handleDelete = () => {
    deleteAction(action.data.id)
    setAction(null)
  }

  const handleSave = (e) => {
    if (e.key === 'Enter') {
      saveAction(action)
      setEditMode(null)
      setAction(null)
    }
  }

  const uploadCandidate = (job) => {
    setIsUpload(job.id)
  }
  const popover = (
    <Card id="popover-basic">
      <Card.Content>
        <P size="14px" height="19px" center>
          {showEditOption
            ? 'Are you sure you want to delete?'
            : 'Are you sure you want to reject?'}
        </P>
        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <A onClick={handleDelete}>Yes</A>
          <A onClick={handleClose} style={{ marginLeft: '60px' }}>
            No
          </A>
        </div>
      </Card.Content>
    </Card>
  )

  return (
    <div className="table-responsive">
      <table className="table table-bordered table-striped table-hover">
        <thead className="table-active">
          <tr className="tr">
            {columNames.map((colData, idx) => (
              <th key={idx + colData.name} className={colData.nowrap}>
                <P weight="bold" size="14px" height="19px" color="#3B3847">
                  {colData.name}
                </P>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowValues.length > 0 ? (
            rowValues.map((rowValue, idx) => (
              <tr
                key={rowValue.id}
                onKeyPress={handleSave}
                edit={editMode - 1 == idx}
                className={rowValue?.rowClassName}
              >
                {columNames.map((columData, colIdx) => {
                  if(columData.name === "#"){
                    let arr = []
                    return (
                      <td
                        className={columData.nowrap}
                        key={colIdx + columData.name}
                      >
                        <input type='checkbox' id={`chk_${rowValue.id}`} key={rowValue.id} onClick={() => { 
                          multipleIdsFunc(rowValue.id)  
                        }} />
                      </td>
                    )
                  }
                  if (columData.name === "No.") {
                    return (
                      <td
                        className={columData.nowrap}
                        key={colIdx + columData.name}
                      >
                        <P size="14px" height="19px" color="#3B3847">
                          {activePage * perPage + idx + 1}
                        </P>
                      </td>
                    )
                  }
                  if (columData.field === 'options') {
                    return (
                      <td
                        className={columData.nowrap}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        key={colIdx + columData.name}
                      >
                        {editMode - 1 == idx ? (
                          <>
                            <A
                              onClick={() => {
                                saveAction(action)
                                setEditMode(null)
                                setAction(null)
                              }}
                            >
                              Save
                            </A>
                            <A
                              onClick={() => {
                                setAction(null)
                                setEditMode(null)
                                if (addNewRow) deleteAction(-1)
                              }}
                            >
                              Cancle
                            </A>
                          </>
                        ) : (
                          <>
                            {showEditOption ? (
                              <a
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => goToEditPage(rowValue.id)}
                                style={{marginRight:'10px', color:"#4C68FF",cursor:'pointer'}}
                              >
                                <i size='20'  color="#4C68FF" data-feather="edit-2"/>
                              </a>
                            ) : (
                              <A
                                onClick={() => {
                                  saveAction({
                                    data: rowValue,
                                  })
                                }}
                              >
                                <Image src={CheckIcon} />
                                
                              </A>
                            )}
                            <OverlayTrigger
                              trigger="click"
                              overlay={popover}
                              placement="bottom"
                              show={action && action.data.id === rowValue.id}
                            >
                              <A
                                onClick={() => {
                                  setAction({
                                    type: 'delete',
                                    data: rowValue,
                                  })
                                }}
                                style={{marginRight:'10px'}}
                              >
                               {showEditOption ? <i size='20'  color="#4C68FF" data-feather="trash-2"/>:
                                <Image
                                  src={XIcon}
                                /> }
                              </A>
                            </OverlayTrigger>
                            
                            {columData?.options?.uploadCanddate &&
                            <span
                              onClick={() => uploadCandidate(rowValue)}  style={{cursor: 'pointer'}}
                            >
                               <i size='20' color="#4C68FF" data-feather="plus-circle"/>
                            </span>}

                            {columData?.options?.timeEntry &&
                            <a
                              style={{
                                cursor: 'pointer',
                              }}
                               href={'/cardinal/time_reports/detail/'+rowValue.id}
                               title='Add/View Time Entry'
                              style={{marginRight:'10px', color:"#4C68FF",cursor:'pointer'}}
                            >
                               <i size='20' color="#4C68FF" data-feather="clock"/>
                            </a>}

                            {columData?.options?.CustomEventIcon &&
                            <span
                              onClick={() => columData?.options?.CustomEventMethod(rowValue.id)}  style={{cursor: 'pointer'}}
                            >
                               <i size='20' color="#4C68FF" data-feather={columData?.options?.CustomEventIcon}/>
                            </span>}

                          </>
                        )}
                      </td>
                    )
                  }
  
                  if (columData.field === 'file') {
                    return (
                      <td
                        className={columData.nowrap}
                        key={colIdx + columData.name}
                      >
                        <A onClick={() => {}}>Download file</A>
                      </td>
                    )
                  }
                  return editMode &&
                    editMode - 1 == idx &&
                    columData.editable &&
                    columData.type === 'select' ? (
                    <td className={colData.nowrap} key={colIdx + columData.name}>
                      <RolePicker
                        // rowValue={rowValue}
                        // updateAction={updateAction}
                        rowValue={editMode && action ? action.data : rowValue}
                        updateAction={(x) => {
                          setAction({
                            type: 'edit',
                            data: {
                              ...action.data,
                              [columData.field]: x.role,
                            },
                          })
                        }}
                      />
                    </td>
                  ) : editMode &&
                    editMode - 1 == idx &&
                    columData.editable &&
                    columData.type === 'text' ? (
                    <td
                      className={columData.nowrap}
                      key={colIdx + columData.name}
                    >
                      <Input
                        type="text"
                        defaultValue={rowValue[columData.field]}
                        onChange={(e) =>
                          // updateAction({
                          //     ...rowValue,
                          //     [columData.field]: e.target.value,
                          // })
                          setAction({
                            type: 'edit',
                            data: {
                              ...action.data,
                              [columData.field]: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                  ) : (
                    <td
                      className={columData.nowrap}
                      key={colIdx + columData.name}
                    >
                      <P size="14px" height="19px" color="#3B3847">
                        {rowValue[columData.field]}
                      </P>
                    </td>
                  )
                })}
              </tr>
            ))
          ) : 
          loading ? (
            <tr>
              <td colSpan={columNames.length}>
                <P className="text-center" size="14px" height="19px" color="#3B3847">
                  <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                  </Spinner>
                </P>
              </td>
            </tr>
          ) :
          (
            <tr>
              <td colSpan={columNames.length}>
                <P className="text-center" size="14px" height="19px" color="#3B3847">No record found</P>
              </td>
            </tr>
          )}
        </tbody>
        { totalValues &&
          <tfoot>
            {(rowValues.length > 0) ? (
                columNames.map((colData, idx) => (
                  <th key={idx + colData.name} className={colData.nowrap}>
                      <P weight="bold" size="14px" height="19px" color="#3B3847">
                          {totalValues[colData.field]}
                        </P>
                  </th>
                ))
            ) : ''}
          </tfoot>
        }
      </table>
    </div>
  )
}

export default xTable
