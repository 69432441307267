import React, { useEffect, useState, useContext, useRef } from 'react'
import ReCaptchaV2 from 'react-google-recaptcha'
import feather from 'feather-icons'
import Select from 'react-select'
import { Row, Col, Form, Alert, Dropdown, Tabs, Tab, Nav, Image, Spinner } from 'react-bootstrap'
import { useField , Formik, Field ,Form as FormSubmit } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import {Button } from '../Admin/styles/UserManagementEditPage.styled'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
// import { Row, Col, Form} from 'react-bootstrap'
import PlusIcon from '../../../../assets/images/icons/plus-icon-v2.svg'
import styles from './styles/CandidateInfoPanel.module.scss'
import './styles/UpdateProfilePage.scss'
import {
    Wrapper,
    P
} from './styles/UpdateProfilePage.styled'
import { nanoid } from 'nanoid'
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import FileButton from '../../common/FileButton/FileButton'
import ProfileUploader from './ProfileUploader';
import Modal from '../../common/Styled components/Modal'
import CropImageModal from './CropImageModal'
import CandidateTwoImage from '../../../../assets/images/img_avatar.png'

const becomeRecruiterValidation = {
    first_name: Yup.string()
      .required('First name is required')
      .test(
        'first name alphabets only',
        'First Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    last_name: Yup.string()
      .required('Last Name is required')
      .test(
        'last name alphabets only',
        'Last Name can only contain alphabet characters and one space in between if there are two words',
        function (value) {
            const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
            return regex.test(value)
        }
    ),
    about: Yup.string()
      .required('About is required'
    ),
    location:Yup.string()
        .required('Location is required'
    ),
    rate: Yup.number('Only numbers allowed in rate')
        .typeError(
            'Invalid Rate, please add numbers only'
        )
        .required('Hourly Rate is required')
        .max(500, "Max hourly rate will be 500")
        .positive("Negative number not allowed"),

}


const MyTextField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
      <>
        <Form.Label column sm="2">
        {label}
        </Form.Label>
        <Col sm="10">
            <Field className="form-control" id={field.name} name={field.name} component={props.type} placeholder={props.placeholder}/>
            {/* <Field className="form-control" id={field.name} name={field.name} component={props.type} placeholder={props.placeholder}/> */}
            {meta.touched && meta.error ? (
            <div className="error">{meta.error}</div>
            ) : null}
        </Col>
      </>
    );
};

const industriesList = [
    { value: 'Administartion', label: 'Administartion' },
    { value: 'Information Technology and Services', label: 'Information Technology and Services' },
    { value: 'Hospital & Health Care', label: 'Hospital & Health Care' },
    { value: 'Financial Services', label: 'Financial Services' },
    { value: 'Accounting', label: 'Accounting' },
    { value: 'Computer Software', label: 'Computer Software' },
    { value: 'Government Administration', label: 'Government Administration' },
    { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },
    { value: 'Human Resources', label: 'Human Resources' },
    { value: 'Management Consulting', label: 'Management Consulting' },
    { value: 'Logistics & Supply Chain', label: 'Logistics & Supply Chain' },
    { value: 'Architecture & Planning', label: 'Architecture & Planning' },
    { value: 'Facilities Services', label: 'Facilities Services' },
    { value: 'Professional Training & Coaching', label: 'Professional Training & Coaching' },
    { value: 'Graphic Design', label: 'Graphic Design' },
    { value: 'Broadcast Media', label: 'Broadcast Media' },
    { value: 'Business Supplies & Equipment', label: 'Business Supplies & Equipment' },
    { value: 'Computer Hardware', label: 'Computer Hardware' },
    { value: 'Computer & Network Security', label: 'Computer & Network Security' },
    { value: 'Investment Management', label: 'Investment Management' },
    { value: 'Information Services', label: 'Information Services' },
    { value: 'Product Management', label: 'Product Management' },
    { value: 'Project Management', label: 'Project Management' },
    { value: 'Data Analytics', label: 'Data Analytics' },
    { value: 'Dev-Ops', label: 'Dev-Ops' },
    { value: 'Events Services', label: 'Events Services' },
    { value: 'Executive Office', label: 'Executive Office' },
    { value: 'Packaging and Containers', label: 'Packaging and Containers' },
    { value: 'Market Research', label: 'Market Research' }

];

const businessSizesList = [
    { value: 'Business with 1-9 Employees', label: 'Business with 1-9 Employees' },
    { value: 'Business with 10-99 Employees', label: 'Business with 10-99 Employees' },
    { value: 'Fortune 500 Company', label: 'Fortune 500 Company' }
];

const techTalentList = [
    { value: 'Recruiters', label: 'Recruiters' },
    { value: 'Software Engineers', label: 'Software Engineers' },
    { value: 'Marketers', label: 'Marketers' },
    { value: 'Designers', label: 'Designers' },
    { value: 'Account Executives', label: 'Account Executives' },
    { value: 'SDRs', label: 'SDRs' },
    { value: 'Accounting / Finance', label: 'Accounting / Finance' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Customer Succes', label: 'Customer Succes' },
    { value: 'HR', label: 'HR' },
    { value: 'Legal', label: 'Legal' },
];

const recruiterCategoryList = [
    { value: 'Accounting/Finance', label: 'Accounting/Finance' },
    { value: 'AI / ML', label: 'AI / ML' },
    { value: 'Big Data', label: 'Big Data' },
    { value: 'Crypto', label: 'Crypto' },
    { value: 'Fintech', label: 'Fintech' },
    { value: 'Health Care', label: 'Health Care' },
    { value: 'Health Tech', label: 'Health Tech' },
    { value: 'Legal', label: 'Legal' },
    { value: 'Medical Device', label: 'Medical Device' },
    { value: 'Technical', label: 'Technical' },
    { value: 'Sales', label: 'Sales' },
];


const UpdateProfilePage = ({user,currentUser}) => {
    const validationSchema = Yup.object({ ...becomeRecruiterValidation })
    const [candidateUploadData, setCandidateUploadData] = useState({
        first_name: user.first_name,
        rate: '',
        last_name: user.last_name,
        experiences: user?.cr_recruiter_detail?.experiences.length > 0 ? user?.cr_recruiter_detail?.experiences : [],
        educations: user?.cr_recruiter_detail?.educations.length > 0 ? user?.cr_recruiter_detail?.educations : [],
        skills: user?.cr_recruiter_detail?.skills?.split(',').length > 0 ? [ ...user?.cr_recruiter_detail?.skills?.split(',').map((skill) => {
            return {
                value: skill.trim().toLowerCase(),
                label: skill.trim().toLowerCase(),
                autoMatched: false,
                };
            }),
        ] : [],
        industries: user?.cr_recruiter_detail?.industries?.split(',').length > 0 ? [...user?.cr_recruiter_detail?.industries.split(',').map((industry) => {
            return {
                value: industry.trim(),
                label: industry.trim(),
                };
            }),
        ] : [],
        businessSizes: user?.cr_recruiter_detail?.business_sizes?.split(',').length > 0 ? [...user?.cr_recruiter_detail?.business_sizes.split(',').map((business_size) => {
            return {
                value: business_size.trim(),
                label: business_size.trim(),
                };
            }),
        ] : [],
       
        techTalents: user?.cr_recruiter_detail?.tech_talents?.split(',').length > 0 ? [...user?.cr_recruiter_detail?.tech_talents.split(',').map((techTalent) => {
            return {
                value: techTalent.trim(),
                label: techTalent.trim(),
                };
            }),
        ] : [],
        recruiterCategories: user?.cr_recruiter_detail?.recruiter_category?.split(',').length > 0 ? [...user?.cr_recruiter_detail?.recruiter_category.split(',').map((recruiterCategory) => {
            return {
                value: recruiterCategory.trim(),
                label: recruiterCategory.trim(),
                };
            }),
        ] : [],
    })

    const [validationError, setValidationError] = useState({
        skills: null,
        experiences: null,
        educations: null,
        techTalents: null,
        recruiterCategories: null
    });

    const [token, setToken] = useState('')
    const [resume, setResume] = useState(null)
    const [resumeError, setResumeError] = useState(null)
    // const { open, setOpen, format_logo_url } = props
    const [avatar_url, setAvatarUrl] = useState(false)
    const [openInner, setOpenInner] = useState(false)
    const [profile, setProfile] = useState()
    const [source, setSource] = React.useState(null);
    const [fileError, setFileError] = useState('')

    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const togglePlay = () => {
        const video = videoRef.current;
        if (video.paused) {
        video.play();
        setIsPlaying(true);
        } else {
        video.pause();
        setIsPlaying(false);
        }
    };

    const handleBecomeRecruiter = (data,resetForm) => {
        let URL = '/recruiter/profile_update'
        let method = 'post'
        const skillsString = candidateUploadData.skills.map((skill)=> skill.value).join(',')
        const industryString = candidateUploadData.industries.map((industry)=> industry.value).join(',')
        const businessSizesString = candidateUploadData.businessSizes.map((businessSize)=> businessSize.value).join(',')
        const educationsString = JSON.stringify(candidateUploadData.educations)
        const experiencesString = JSON.stringify(candidateUploadData.experiences)
        const techTalentString = candidateUploadData.techTalents.map((techTalent)=> techTalent.value).join(',')
        const recruiterCategoryString = candidateUploadData.recruiterCategories.map((recruiterCategory)=> recruiterCategory.value).join(',')
        const payload = new FormData()
        payload.append('user[user_id]', user.id)
        payload.append('user[first_name]', data.first_name)
        payload.append('user[last_name]', data.last_name)
        payload.append('user[rate]', data.rate)
        payload.append('user[about]', data.about)
        payload.append('user[location]', data.location)
        payload.append('user[skills]', skillsString)
        payload.append('user[industries]', industryString)
        payload.append('user[business_sizes]', businessSizesString)
        payload.append('user[educations]', educationsString.replace(/\\/g, ""))
        payload.append('user[experiences]', experiencesString.replace(/\\/g, ""))
        payload.append('user[tech_talents]', techTalentString)
        payload.append('user[recruiter_category]', recruiterCategoryString)
        payload.append('user[token]',token)
        if(resume){
            payload.append('user[profile_pic]',resume)
        }
        if(avatar_url){
            payload.append('user[profile_pic]',avatar_url)    
        }

        if(source){
            payload.append('user[rec_video]',source)    
        }

        makeRequest(URL,'post',payload , {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                if(responce['messageType'] == 'failure'){
                    return {
                        message: responce['msg'],
                        messageType: responce['messageType'],
                        loading: false,
                        autoClose: true,
                    }
                }else{
                    return {
                        message: responce['msg'],
                        messageType: responce['messageType'],
                        loading: false,
                        autoClose: true,
                    }
                }
            },
            createErrorMessage: (e) => 'Failed',
            onSuccess: (responce) => {
                if(responce['data']['messageType'] == "success"){
                    window.location.href = '/' 
                }else{
                    if (currentUser == null){
                        window.grecaptcha.reset();
                    }
                }
            },
        })
    }

    const handleToken = (token) => {
        setToken(token);
        setForm((currentForm) => {
         return {...currentForm, token }
        })
    }

    const handleExpire = () => {
        setForm((currentForm) => {
            return {...currentForm, token: null }
        })
    }
    const format_logo_url = (avatar_url) => {
        if (typeof avatar_url == 'object') {
            return URL.createObjectURL(avatar_url)
        }
        return avatar_url
    }

    const handlePictureError = (error) => {
        setResumeError(error)
    }
    const handlePicture = (file) => {
        if (file && file.size > 1024 * 1024 * 4) {
            setResumeError('File size cannot exceed more than 4MB')
        } else {
            setResumeError('')
            setProfile(format_logo_url(file))
        }
    }
    const saveCropImage = (upImg) => {
        setAvatarUrl(upImg)
        setOpenInner(!openInner)
    }

    const handelInput = (event) => {
        const { name, value } = event.target
        setValidationMessages()
        setState({ ...initialState, [name]: value })
    }
    
    const showExperienceForm = () => {
        const newExperience = {
            id: nanoid(),
            title: '',
            company_name: '',
            location: '',
            start_date: '',
            end_date: '',
            description: '',
        }
        setCandidateUploadData({
            ...candidateUploadData,
            experiences: [...candidateUploadData.experiences, newExperience],
        })
    }

    const addNewExperience = (exp) => {
        setCandidateUploadData({
            ...candidateUploadData,
            experiences: candidateUploadData.experiences.map((e) => {
                if (e.id === exp.id) return exp
                else return e
            }),
        })
    }

    const removeExperience = (exp) => {
        setCandidateUploadData({
            ...candidateUploadData,
            experiences: candidateUploadData.experiences.filter(
                (e) => e.id != exp.id
            ),
        })
    }

    const showEducationForm = () => {
        const newEducation = {
            id: nanoid(),
            school_name: '',
            degree: '',
            from: '',
            to: '',
        }
        setCandidateUploadData({
            ...candidateUploadData,
            educations: [...candidateUploadData.educations, newEducation],
        })
    }

    const addNewEducation = (exp) => {
        setCandidateUploadData({
            ...candidateUploadData,
            educations: candidateUploadData.educations.map((e) => {
                if (e.id === exp.id) return exp
                else return e
            }),
        })
    }

    const removeEducation = (edu) => {
        setCandidateUploadData({
            ...candidateUploadData,
            educations: candidateUploadData.educations.filter(
                (e) => e.id != edu.id
            ),
        })
    }
    

    const handleMustHaveSkills = (event) => {
        // if (candidateUploadData.mustHaveSkills.length > event.length) {
        //   const removedElements = _.difference(candidateUploadData.mustHaveSkills, event);
        //   setRemoved([...removed, ...removedElements]);
        //   const newSkillOptions = _.differenceBy(skillOptions, removedElements, 'value' );
        //   setSkillOptions([...newSkillOptions]);
        // }
        setCandidateUploadData({ ...candidateUploadData, skills: [...event] });
        setValidationError({ ...validationError, skills: null });
    };

    const handleIndustriesList = (event) => {
        setCandidateUploadData({ ...candidateUploadData, industries: [...event] });
        setValidationError({ ...validationError, industries: null });
    };

    const handleTechTalentList = (event) => {
        setCandidateUploadData({ ...candidateUploadData, techTalents: [...event] });
        setValidationError({ ...validationError, techTalents: null });
    };

    const handleRecruiterCategoryList = (event) => {
        setCandidateUploadData({ ...candidateUploadData, recruiterCategories: [...event] });
        setValidationError({ ...validationError, recruiterCategories: null });
    };

    const handleBusinessSizesList = (event) => {
        setCandidateUploadData({ ...candidateUploadData, businessSizes: [...event] });
        setValidationError({ ...validationError, businessSizes: null });
    };

    const onCreateSkills = (event) => {
        const newSkill = { value: event, label: event, autoMatched: false };
        const cloneSkills = candidateUploadData.skills;
        cloneSkills.push(newSkill);
        setCandidateUploadData({ ...candidateUploadData, skills: [...cloneSkills] });
        setValidationError({ ...validationError, skills: null });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // const url = URL.createObjectURL(file);
        if(file?.size > 50000000){
            setFileError( 'The uploaded file should be less than 50MB')
            setSource(file);
        }
        else{
            if((!file.type.includes('mp4')) && !(file.type.includes('mp3')) && !(file.type.includes('audio')) && !(file.type.includes('video'))){
                setFileError("invalid format")
                setSource(file);
            }else{              
                setFileError('')    
                setSource(file);
            }
        }
    };

    const checkValidation = () => {
        setValidationError({})
        let isValid = true
        if (_.isEmpty(candidateUploadData.skills)) {
            setValidationError((prev) => ({
                ...prev,
                skills: 'Skills are required',
            }));
            isValid = false
        }
        if (_.isEmpty(candidateUploadData.industries)) {
            setValidationError((prev) => ({
                ...prev,
                industries: 'Industries are required',
            }));
            isValid = false
        }
        if (_.isEmpty(candidateUploadData.techTalents)) {
            setValidationError((prev) => ({
                ...prev,
                techTalents: 'Tech Talent are required',
            }));
            isValid = false
        }
        if (_.isEmpty(candidateUploadData.recruiterCategories)) {
            setValidationError((prev) => ({
                ...prev,
                recruiterCategories: 'Type of Recruiter are required',
            }));
            isValid = false
        }
        if(candidateUploadData.techTalents?.length > 5){
            setValidationError((prev) => ({
                ...prev,
                techTalents: 'Max 5 Tech Talents are allowed',
            }));
            isValid = false
        }
        if(candidateUploadData.recruiterCategories?.length > 5){
            setValidationError((prev) => ({
                ...prev,
                techTalents: 'Max 5 Types of recruiters are allowed',
            }));
            isValid = false
        }
        if (_.isEmpty(candidateUploadData.businessSizes)) {
            setValidationError((prev) => ({
                ...prev,
                businessSizes: 'Business Sizes are required',
            }));
            isValid = false
        }

        if (
            !avatar_url && user.ct_profile_pic == null
        ) {
            setResumeError('Profile picture is required')
            isValid = false
        }

        if(candidateUploadData.experiences?.length == 0){
            setValidationError((prev) => ({
                ...prev,
                experiences: 'Minimum 1 Job Experience is required.',
            }));
            isValid = false
        }else{
            let job_exp_status  = true
            candidateUploadData?.experiences?.map((e,i)=>{
                if(e.title?.length == 0){
                    setValidationError((prev) => ({
                        ...prev,
                        experiences: 'Please enter position title for job experience',
                    }));
                    isValid = false
                }
                if(e.company_name?.length == 0){
                    setValidationError((prev) => ({
                        ...prev,
                        experiences: 'Please enter company for the job experience.',
                    }));
                    isValid = false
                }
                if(e.start_date?.length == 0){
                    setValidationError((prev) => ({
                        ...prev,
                        experiences: 'Please enter start date for the job experience',
                    }));
                    isValid = false
                }
                if(e.location?.length == 0){
                    setValidationError((prev) => ({
                        ...prev,
                        experiences: 'Please enter location for the job experience',
                    }));
                    isValid = false
                }
                // Check same campany name and job title
                // if(candidateUploadData?.job_experiences?.filter(s => s?.company_name === e?.company_name && s?.title === e?.title).length > 1){
                //     setValidationErrors({
                //         ...validationErrors,
                //         job_experiences: 'Two or more job experiences have the same value for title and company. Please correct it.',
                //     })
                //     job_exp_status=false
                // }
            })
        }
        if(candidateUploadData.educations?.length == 0){
            setValidationError((prev) => ({
                ...prev,
                educations: 'Minimum 1 Education is required.',
            }));
            isValid = false
        }else{
            candidateUploadData?.educations?.map((e,i)=>{
                if(e.to?.length == 0){
                    setValidationError((prev) => ({
                        ...prev,
                        educations: 'Please enter to date for the education',
                    }));
                    isValid = false
                }
                if(e.from?.length == 0){
                    setValidationError((prev) => ({
                        ...prev,
                        educations: 'Please enter from date for the education',
                    }));
                    isValid = false
                }
                if(e.degree?.length == 0){
                    setValidationError((prev) => ({
                        ...prev,
                        educations: 'Please enter degreee for the education',
                    }));
                    isValid = false
                }
                if(e.school_name?.length == 0){
                    setValidationError((prev) => ({
                        ...prev,
                        educations: 'Please enter school name for the education',
                    }));
                    isValid = false
                }

                if(candidateUploadData?.educations?.filter(s => s?.from.trim() === e?.from.trim() && s?.to.trim() === e?.to.trim() && s?.school_name.trim() === e?.school_name.trim() && s?.degree.trim() === e?.degree.trim() ).length > 1){
                    setValidationError  ({
                        ...prev,
                        educations: 'Duplicate education details are not allowed'
                    })
                    isValid = false
                }
            })
        }
        return isValid
    };
    

    return (
        <Wrapper>
            {/* <AboutUsSection className="row">
                <div className="col-md-6">
                    <AboutUsText>
                        <P>
                            From problem solving to innovational thinking, our team
                            strives for the best.
                        </P>
                    </AboutUsText>
                </div>
                <div className="col-md-6">
                    <Image className="img-fluid w-100" src={ImageOne} />
                </div>
            </AboutUsSection> */}
            <div className="formLayout">
                <div className="innerForm">
                    {/* <div className="d-flex flex-column">
                        <P size="40px" height="40px" marginBottom="40px">
                            Recruiter Form
                        </P>
                    </div> */}
                    <div className="col-xl-8 col-lg-6 col-md-8 col-sm-10 mx-auto form">
                        <div className="d-flex flex-column">
                            <P size="40px" height="40px" marginBottom="40px">
                                Update Profile
                            </P>
                        </div>
                        <Formik
                            initialValues={{
                                first_name: user.first_name,
                                last_name: user.last_name,
                                rate: user?.cr_recruiter_detail?.rate,
                                about:user?.cr_recruiter_detail?.about,
                                location:user.location,
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={validationSchema}
                            validate={(values) => {
                                const errors = {}
                                checkValidation()
                                return errors
                            }}
                            onSubmit={(values, { setSubmitting ,resetForm }) => {
                                const isValid = checkValidation()
                                if(isValid){
                                    setTimeout(() => {
                                        setSubmitting(false);
                                        handleBecomeRecruiter(values,resetForm);
                                    }, 400);
                                }
                            }}
                        >
                            <FormSubmit>
                                <Form.Group as={Row} className="mb-3" controlId="formFirstName">
                                    <MyTextField name="first_name" type="input" label="First Name*" placeholder="First Name"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formLastName">
                                    <MyTextField name="last_name" type="input" label="Last Name*" placeholder="Last Name"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formRate">
                                    <MyTextField name="rate" type="input" label="Hourly Rate($)*" placeholder="Hourly Rate"/>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formLocation">
                                    <MyTextField name="location" type="input" label="Location*" placeholder="Location"/>
                                </Form.Group>
                                <div className="row">
                                    <div className="col-md-6">
                                        {(user?.ct_profile_pic != null || avatar_url != null) && 
                                            <div className="image">
                                                <Image src={ avatar_url == null || avatar_url == '' ? (user?.ct_profile_pic == null ? CandidateTwoImage : user?.ct_profile_pic ) : 
                                                        format_logo_url(avatar_url)
                                                } style={{ height: '200px',width: '200px',marginBottom: '10px' }}/>
                                            </div> 
                                        }
                                        <Form.Group as={Row} className="mb-3" controlId="formLinkdinUrl">
                                            <div style={{ marginLeft: '12px' }}>
                                                <ProfileUploader
                                                    onFileSelectSuccess={(file) =>{
                                                        handlePicture(file)
                                                        setOpenInner(!openInner)
                                                        }
                                                    }
                                                    onFileSelectError={({ error }) =>
                                                        handlePictureError(error)
                                                    }
                                                    isProfilePicture={'profilePicture'}
                                                />
                                                { profile && (
                                                    <Modal
                                                        width={'50%'}
                                                        isOpen={openInner}
                                                        onBlur={() => setOpenInner(!openInner)}
                                                    >
                                                        <CropImageModal
                                                            setOpenInner={setOpenInner}
                                                            profile={profile}
                                                            saveCropImage={saveCropImage} 
                                                            handlePicture = {(file) => {handlePicture(file)}}
                                                        />
                                                    </Modal>
                                                )}
                                                {resumeError && (
                                                    <div className="error">{resumeError}</div>
                                                )}
                                            </div>
                                        </Form.Group> 
                                    </div>
                                    <div className="col-md-6">
                                        { (user?.video_url != null) && 
                                            <video ref={videoRef} width="320" height="200" controls autoPlay={true} onClick={togglePlay}>
                                                <source src={user?.video_url} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video> 
                                        }
                                        <Form.Group as={Row} className="mb-3 ml-1" controlId="formLinkdinUrl">
                                            <FileButton
                                                type="button"
                                                label="Upload Video"
                                                file='video_file'
                                                file_type='video'
                                                resumeError={resumeError}
                                                setResumeError={setResumeError}
                                                getFile={(file) => setSource(file)}
                                            />
                                        </Form.Group>
                                    </div>    
                                </div>
                                <Form.Group as={Row} className="mb-3" controlId="formRate">
                                    <Form.Label column sm="2">Skills*</Form.Label>
                                    <Col sm="10">
                                    <CreatableSelect
                                        isMulti
                                        createOptionPosition="first"
                                        // styles={colourStyles}
                                        onChange={handleMustHaveSkills}
                                        value={candidateUploadData.skills}
                                        options=''
                                        menuPlacement="auto"
                                        onCreateOption={onCreateSkills}
                                    />
                                    {validationError.skills && (
                                        <div className="error">{validationError.skills}</div>
                                    )}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formRate">
                                    <Form.Label column sm="2">Industries*</Form.Label>
                                    <Col sm="10">
                                        <Select
                                            options={industriesList}
                                            name='industries'
                                            id='industries'
                                            value= {candidateUploadData.industries}
                                            placeholder={'Select Industries'}
                                            onChange={handleIndustriesList}
                                            isMulti
                                        />
                                        {validationError.industries && (
                                            <div className="error">{validationError.industries}</div>
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formRate">
                                    <Form.Label column sm="2">Tech Talent*</Form.Label>
                                    <Col sm="10">
                                        <Select
                                            options={techTalentList}
                                            name='techTalents'
                                            id='techTalents'
                                            placeholder={'Select Tech Talents (Max 5)'}
                                            onChange={handleTechTalentList}
                                            isMulti
                                            value= {candidateUploadData.techTalents}
                                        />
                                        {validationError.techTalents && (
                                            <div className="error">{validationError.techTalents}</div>
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formRate">
                                    <Form.Label column sm="2">Type of Recruiters*</Form.Label>
                                    <Col sm="10">
                                        <Select
                                            options={recruiterCategoryList}
                                            name='recruiterCategoryList'
                                            id='recruiterCategoryList'
                                            placeholder={'Select Type of Recruiter (Max 5)'}
                                            onChange={handleRecruiterCategoryList}
                                            isMulti
                                            value= {candidateUploadData.recruiterCategories}
                                        />
                                        {validationError.recruiterCategories && (
                                            <div className="error">{validationError.recruiterCategories}</div>
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formRate">
                                    <Form.Label column sm="2">Business Sizes*</Form.Label>
                                    <Col sm="10">
                                        <Select
                                            options={businessSizesList}
                                            name='businessSizes'
                                            id='businessSizes'
                                            placeholder={'Select Business Sizes'}
                                            onChange={handleBusinessSizesList}
                                            isMulti
                                            value= {candidateUploadData.businessSizes}
                                        />
                                        {validationError.businessSizes && (
                                            <div className="error">{validationError.businessSizes}</div>
                                        )}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formLastName">
                                    <MyTextField name="about" type="textarea" label="About*" placeholder="About"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formEducation">
                                    <p className={styles.tabContentTitle}>
                                        Education*
                                    </p>
                                    <div
                                        className={`${styles.indent} ${styles.tabContentText} ${(validationError['educations']) ? 'expError' :''}`}
                                        style={{
                                            cursor: 'pointer',
                                            border: '1px solid #cccccc',
                                            borderRadius: '2px',
                                            padding: '12px',
                                            marginBottom: '12px',
                                            marginLeft:'0px'
                                        }}
                                    >
                                        {candidateUploadData?.educations?.map(
                                            (edu) => {
                                                return (
                                                <AddNewEducation
                                                    key={edu.id}
                                                    education={edu}
                                                    onChange={
                                                        addNewEducation
                                                    }
                                                    removeEducation={
                                                        removeEducation
                                                    }
                                                />
                                            )}
                                        )}

                                        <div className={`d-flex justify-content-between align-items-center`}>
                                            <p
                                                style={{
                                                    cursor: 'pointer',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                
                                                <a
                                                        onClick={() =>
                                                            showEducationForm()
                                                        }
                                                    >
                                                        <Image
                                                            src={PlusIcon}
                                                            style={{
                                                                width: '10px',
                                                                height: '10px',
                                                                marginRight:
                                                                    '15px',
                                                            }}
                                                        />
                                                        Add education
                                                </a>
                                            </p>
                                        </div>
                                        {validationError.educations && (
                                            <div className="error">{validationError.educations}</div>
                                        )}
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formExperience">
                                    <p className={styles.tabContentTitle}>
                                        Experience*
                                    </p>

                                    <div
                                        className={`${styles.indent} ${styles.tabContentText} ${(validationError    ['experiences']) ? 'expError' :''}`}
                                        style={{
                                            cursor: 'pointer',
                                            border: '1px solid #cccccc',
                                            borderRadius: '2px',
                                            padding: '12px',
                                            marginBottom: '12px',
                                            marginLeft:'0px'
                                        }}
                                    >
                                        {candidateUploadData.experiences.map(
                                            (exp) => (
                                                <AddNewExperience
                                                    key={exp.id}
                                                    experience={exp}
                                                    onChange={
                                                        addNewExperience
                                                    }
                                                    removeExperience={
                                                        removeExperience
                                                    }
                                                    // updateCandidateData={updateCandidateData}
                                                />
                                            )
                                        )}

                                        <div className={`d-flex justify-content-between align-items-center`}>
                                            <p
                                                style={{
                                                    cursor: 'pointer',
                                                    marginBottom: '0px',
                                                }}
                                            >
                                                
                                                <a
                                                    onClick={() =>
                                                        showExperienceForm()
                                                    }
                                                >
                                                    <Image
                                                        src={PlusIcon}
                                                        style={{
                                                            width: '10px',
                                                            height: '10px',
                                                            marginRight:
                                                                '15px',
                                                        }}
                                                    />
                                                    Add experience
                                                </a>
                                            </p>
                                        </div>
                                        {validationError.experiences && (
                                            <div className="error">{validationError.experiences}</div>
                                        )}
                                    </div>
                                </Form.Group>
                                { currentUser == null && (
                                <ReCaptchaV2 
                                    sitekey='6Lf0VJIgAAAAADFu4GqYE5dILecwOI8w_-OutOde'
                                    onChange={handleToken}
                                    onExpire={handleExpire}
                                />)}
                                <Row>
                                    <Col>
                                        <div style={{ marginTop: '18px' }}>
                                            <Button type="submit">Update</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </FormSubmit>
                        </Formik>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

const AddNewExperience = (props) => {
    const { onChange, experience, removeExperience } = props 
    useEffect(() => {
        feather.replace()
    })
    const handleChange = (e) => {
       onChange({ ...experience, [e.target.name]: e.target.value })
    }



    return (
        <div
            className="d-flex align-items-start job-experience"
            style={{ marginBottom: '12px' }}
        >
            <div className="d-flex flex-column w-100">
                <div className="d-flex justify-content-between">
                    <div>
                        <input
                            type="text"
                            name="title"
                            placeholder="Position title"
                            className={`${styles.editInput} ${(experience.title === 'N/A') ? 'expError' :''} position_title`}
                            style={{ width: '190px' }}
                            value={experience.title}
                            onChange={handleChange}
                        />{' '}
                        &nbsp; <span className={styles.symbolText}>@</span>{' '}
                        &nbsp;
                        <input
                            type="text"
                            name="company_name"
                            placeholder="Company"
                            className={`${styles.editInput} ${(experience.company_name === 'N/A') ? 'expError' :''} company_name`}
                            style={{ width: '100px' }}
                            value={experience.company_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="start_date"
                            placeholder="2021-01-25"
                            className={`${styles.editInput} ${(experience.start_date === 'N/A') ? 'expError' :''}`}
                            style={{ width: '90px' }}
                            value={experience.start_date}
                            onChange={handleChange}
                        />{' '}
                        -
                        <input
                            type="text"
                            name="end_date"
                            placeholder="Present"
                            className={`${styles.editInput} ${(experience.end_date === 'N/A') ? 'expError' :''} end_date`}
                            style={{ width: '90px' }}
                            value={experience.end_date}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <input
                    type="text"
                    name="location"
                    placeholder="Location"
                    className={`${styles.editInput} ${(experience.location === 'N/A') ? 'expError' :''}`}
                    value={experience.location}
                    onChange={handleChange}
                />
                <textarea
                    placeholder="Details"
                    name="description"
                    rows="5"
                    style={{ resize: 'none' }}
                    className={`${styles.editInput} ${(experience.description === 'N/A') ? 'expError' :''}`}
                    value={experience.description}
                    onChange={handleChange}
                />
            </div>
            <div
                className={styles.circleButton}
                style={{
                    background: '#4C68FF',
                    color: '#fff',
                    width: '16px',
                    height: '16px',
                }}
                onClick={() => {
                    removeExperience(experience)
                }}
            >
                <i data-feather="x" />
            </div>
            
        </div>
    )
}

const AddNewEducation = (props) => {
    const { onChange, education, removeEducation } = props
    useEffect(() => {
        feather.replace()
    })
    
    const handleChange = (e) => {
        onChange({ ...education, [e.target.name]: e.target.value })
    }

    return (
        <div
            className="d-flex align-items-start"
            style={{ marginBottom: '12px' }}
        >
            <div className="d-flex flex-column w-100">
                <div className="d-flex justify-content-between">
                    <div className='w-100'>
                        <input
                            type="text"
                            name="school_name"
                            placeholder="University"
                            className={styles.editInput}
                            value={education.school_name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className='w-100'>
                        <input
                            type="text"
                            name="from"
                            placeholder="From"
                            className={styles.editInput}
                            style={{ width: '48.5%' }}
                            value={education.from}
                            onChange={handleChange}
                        />{' '}
                        -
                        <input
                            type="text"
                            name="to"
                            placeholder="To"
                            className={styles.editInput}
                            style={{ width: '48%' }}
                            value={education.to}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className='w-100'>
                        <input
                            type="text"
                            name="degree"
                            placeholder="Degree"
                            className={styles.editInput}
                            value={education.degree}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div
                className={styles.circleButton}
                style={{
                    background: '#4C68FF',
                    color: '#fff',
                    width: '16px',
                    height: '16px',
                }}
                onClick={() => {
                    removeEducation(education)
                }}
            >
                <i data-feather="x" />
            </div>
        </div>
    )
}

export default UpdateProfilePage